import { AppDate } from '@common/index';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import 'dayjs/locale/en';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(isBetween);

export class Dates {
  static setLocale(locale: string): void {
    dayjs.locale(locale);
  }

  static getUTCTime(): AppDate {
    return dayjs.utc();
  }

  static getToday(): AppDate {
    return dayjs();
  }

  static getClearDate(): AppDate {
    return this.getToday().hour(0).minute(0).second(0).millisecond(0);
  }

  static getDate(date: number | string): AppDate {
    return dayjs(date);
  }

  static getFirstWeekDay(date: AppDate): AppDate {
    return dayjs(date).startOf('week');
  }

  static getLastWeekDay(date: AppDate): AppDate {
    return dayjs(date).endOf('week');
  }

  static getStartOfDay(date: AppDate): AppDate {
    return dayjs(date).hour(0).minute(0).second(0).millisecond(0);
  }

  static getEndOfDay(date: AppDate): AppDate {
    return dayjs(date).hour(23).minute(59).second(59).millisecond(999);
  }

  static getDaysArrayInRange(startDate: AppDate, endDate: AppDate): AppDate[] {
    const days: AppDate[] = [];

    let iterator = startDate;
    while (iterator.isBefore(endDate)) {
      days.push(iterator);
      iterator = iterator.add(1, 'day');
    }

    return days;
  }

  static isBetween(givenDate: AppDate, firstDate: AppDate, lastDate: AppDate): boolean {
    return givenDate.isBetween(firstDate, lastDate);
  }

  static convertStringToDate(dayString: string): AppDate {
    return dayjs(dayString);
  }

  static convertDateToUTC(date: AppDate): AppDate {
    return date.utc();
  }

  static convertUnixToDate(timestamp: number): AppDate {
    return dayjs.unix(timestamp);
  }

  static isSameDay(date1: AppDate, date2: AppDate) {
    return date1.startOf('day').isSame(date2.startOf('day'));
  }

  static getDayNames(): string[] {
    const dayNames: string[] = [];
    const sampleWeekStart = Dates.getFirstWeekDay(Dates.getToday());
    const sampleWeekEnd = Dates.getLastWeekDay(Dates.getToday());

    let iterator: AppDate = sampleWeekStart;
    while (iterator.isBefore(sampleWeekEnd)) {
      dayNames.push(iterator.format('dddd'));
      iterator = iterator.add(1, 'day');
    }

    return dayNames;
  }
}

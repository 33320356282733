import { CloseOutlined } from '@ant-design/icons';
import { persistClassificationConfidence, persistDetectionConfidence } from '@app/services';
import {
  setClassificationConfidence,
  setDetectionConfidence,
  setStreamingCamera,
} from '@app/store/slices/Streams/slice';
import { BASE_COLORS, StreamingCamera } from '@common/index';
import { BaseForm, H3, Slider, Space } from '@components/index';
import { useAppDispatch, useAppSelector, useDebounce, useResponsive } from '@hooks/index';
import * as coco from '@tensorflow-models/coco-ssd';
import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgl';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VREPlayer from 'videojs-react-enhanced';
import * as S from './StreamingCard.style';

interface IStreamingCardProps {
  camera: StreamingCamera;
  direction: 'vertical' | 'horizontal';
}

const StreamingCard: React.FC<IStreamingCardProps> = ({ direction, camera }) => {
  const FPS = 30;
  const { isTablet } = useResponsive();
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const classLabels = ['Auxiliary', 'Carrier', 'Fishing', 'Military', 'Passenger', 'Tanker', 'Yacht'];

  const playerRef = useRef<any>(null);
  const frameCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const horizontalStreamingCardRef = useRef<HTMLDivElement>(null);

  const storedDetectionConfidence: number = useAppSelector((state) => state.streams.detectionConfidence);
  const storedClassificationConfidence: number = useAppSelector((state) => state.streams.classificationConfidence);

  const [detectionModel, setDetectionModel] = useState<coco.ObjectDetection | null>(null);
  const [classificationModel, setClassificationModel] = useState<any | null>(null);

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    fluid: true,
    sources: [
      {
        src: camera.url,
      },
    ],
  };

  const classificationConfidenceWatch = BaseForm.useWatch('classificationConfidence', form);
  const detectionConfidenceWatch = BaseForm.useWatch('detectionConfidence', form);

  const debouncedClassificationConfidenceValue = useDebounce<number>(classificationConfidenceWatch, 1000);
  const debouncedDetectionConfidenceValue = useDebounce<number>(detectionConfidenceWatch, 1000);

  useEffect(() => {
    const loadModels = async () => {
      const detectionModel = await coco.load({
        base: 'mobilenet_v2',
      });
      setDetectionModel(detectionModel);

      const classification_model_url = 'classification.tfjs/model.json';
      const classificationModel = await tf.loadGraphModel(classification_model_url);
      setClassificationModel(classificationModel);
    };

    loadModels();
  }, []);

  useEffect(() => {
    form.setFieldValue('detectionConfidence', storedDetectionConfidence * 100);
  }, [storedDetectionConfidence, form]);

  useEffect(() => {
    form.setFieldValue('classificationConfidence', storedClassificationConfidence * 100);
  }, [storedClassificationConfidence, form]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const processImageForClassification = (imageElement: HTMLCanvasElement) => {
      let img = tf.browser.fromPixels(imageElement);
      img = tf.image.resizeBilinear(img, [224, 224]).div(tf.scalar(255));
      img = tf.cast(img, 'float32');
      let meanRgb = { red: 0.485, green: 0.456, blue: 0.406 };
      let stdRgb = { red: 0.229, green: 0.224, blue: 0.225 };
      let indices = [tf.tensor1d([0], 'int32'), tf.tensor1d([1], 'int32'), tf.tensor1d([2], 'int32')];
      let centeredRgb = {
        red: tf.gather(img, indices[0], 2).sub(tf.scalar(meanRgb.red)).div(tf.scalar(stdRgb.red)).reshape([224, 224]),

        green: tf
          .gather(img, indices[1], 2)
          .sub(tf.scalar(meanRgb.green))
          .div(tf.scalar(stdRgb.green))
          .reshape([224, 224]),

        blue: tf
          .gather(img, indices[2], 2)
          .sub(tf.scalar(meanRgb.blue))
          .div(tf.scalar(stdRgb.blue))
          .reshape([224, 224]),
      };
      let processedImg = tf.stack([centeredRgb.red, centeredRgb.green, centeredRgb.blue]).expandDims();
      return processedImg;
    };

    const cropImage = (canvas: HTMLCanvasElement, [x, y, w, h]: number[]) => {
      const result = document.createElement('canvas');

      const increment = w * 0.2;
      x = x - increment;
      y = y - increment;
      w += 2 * increment;
      h += 2 * increment;

      if (x < 0) x = 0;
      if (y < 0) y = 0;
      if (canvas.width < x + w) w = canvas.width - x;
      if (canvas.height < y + h) h = canvas.height - y;

      result.width = w;
      result.height = h;
      const ctx = result.getContext('2d');

      ctx?.drawImage(canvas, x, y, w, h, 0, 0, w, h);
      return result;
    };

    const findIndex = (values: Float32Array): number => {
      let index = -1;
      let max = -1000;
      for (let i = 0; i < values.length; i++) {
        if (values[i] >= max) {
          max = values[i];
          index = i;
        }
      }
      return index;
    };

    const detectObjects = async (frameContext: HTMLCanvasElement) => {
      let classifiedObjects: {
        predictClass: string;
        predictConfidence: number;
        bbox: [number, number, number, number];
        class: string;
        score: number;
      }[] = [];

      if (detectionModel != null) {
        await detectionModel.detect(frameContext).then(async (detectedObjects: any) => {
          if (classificationModel != null) {
            classifiedObjects = detectedObjects
              .filter((pp: any) => pp.class === 'boat')
              .map((p: any, i: any) => {
                const image = cropImage(frameContext, p.bbox);
                const classificationResult = classificationModel.predict(processImageForClassification(image));
                const predictions = classificationResult.dataSync();
                const predictedIndex = findIndex(predictions);
                const predictedClass = classLabels[predictedIndex];
                const confidence = Math.round(predictions[predictedIndex] * 10) / 100;
                return { ...p, predictClass: predictedClass, predictConfidence: confidence };
              });
          }
        });
      }

      return classifiedObjects;
    };

    const drawResult = (
      frameContext: CanvasRenderingContext2D,
      [x, y, w, h]: number[],
      label: string,
      confidence: number,
      predictClass: string,
      predictConfidence: number,
    ) => {
      const color = BASE_COLORS.orange;
      let text = '';

      if (classificationConfidenceWatch.toFixed(0) / 100 <= predictConfidence) {
        text = `${predictClass} %${(predictConfidence * 100 > 100 ? 100 : predictConfidence * 100).toFixed(0)}`;
      }

      frameContext.beginPath();
      frameContext.font = '36px Arial';
      frameContext.strokeStyle = color;
      frameContext.lineWidth = 2;
      frameContext.strokeRect(x, y, w, h);
      frameContext.fillStyle = color;

      if (text != '') {
        const textSize = frameContext.measureText(text).width;
        frameContext.rect(x + 1, y - 40, textSize + 12, 40);
        frameContext.fill();
        frameContext.fillStyle = 'white';
        frameContext.fillText(text, x + 5, y - 5);
      }
      frameContext.closePath();
    };

    const drawResults = (frameContext: CanvasRenderingContext2D, predictions: any[]) => {
      predictions.map((p) => drawResult(frameContext, p.bbox, p.class, p.score, p.predictClass, p.predictConfidence));
    };

    const processVideo = async () => {
      let delay = 0;
      let begin = Date.now();
      delay = 1000 / FPS - (Date.now() - begin);

      const playerExist = !!playerRef;
      const frameCanvasExist = !!frameCanvasRef;

      if (frameCanvasExist && frameCanvasRef.current && playerRef && playerRef.current) {
        const frameContext = frameCanvasRef.current.getContext('2d');

        frameContext?.drawImage(
          playerRef.current.children_[0],
          0,
          0,
          frameCanvasRef.current.width,
          frameCanvasRef.current.height,
        );

        const detectedObjects = await detectObjects(frameCanvasRef.current);
        const filteredDetections = detectedObjects.filter(
          (d) => detectionConfidenceWatch.toFixed(0) / 100 <= d.score && d.class === 'boat',
        );

        frameContext && drawResults(frameContext, filteredDetections);
      }

      timeout = setTimeout(() => {
        processVideo();
      }, 30);
    };

    processVideo();

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [detectionModel, debouncedClassificationConfidenceValue, debouncedDetectionConfidenceValue]);

  useEffect(() => {
    const normalizedValue = debouncedClassificationConfidenceValue / 100;
    normalizedValue && dispatch(setClassificationConfidence(normalizedValue));
    persistClassificationConfidence(normalizedValue);
  }, [debouncedClassificationConfidenceValue]);

  useEffect(() => {
    const normalizedValue = debouncedDetectionConfidenceValue / 100;
    normalizedValue && dispatch(setDetectionConfidence(normalizedValue));
    persistDetectionConfidence(normalizedValue);
  }, [debouncedDetectionConfidenceValue]);

  const clearStreamingCam = () => {
    dispatch(setStreamingCamera(null));
  };

  const renderVerticalLayout = () => {
    return (
      <S.VerticalStreamingCard>
        <Space direction="vertical">
          <Row justify="space-between" align="middle">
            <H3>{camera.name}</H3>
            <S.CloseButton icon={<CloseOutlined />} onClick={clearStreamingCam} />
          </Row>

          <BaseForm form={form}>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <BaseForm.Item
                  name="detectionConfidence"
                  label={`${t('pages.home.detectionConfidence')} (${detectionConfidenceWatch?.toFixed(0)}%)`}
                >
                  <Slider />
                </BaseForm.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <BaseForm.Item
                  name="classificationConfidence"
                  label={`${t('pages.home.classificationConfidence')} (${classificationConfidenceWatch?.toFixed(0)}%)`}
                >
                  <Slider />
                </BaseForm.Item>
              </Col>
            </Row>
          </BaseForm>
        </Space>

        <S.VREContainer>
          <VREPlayer
            key={camera.url}
            videojsOptions={videoJsOptions}
            onReady={(player: any) => (playerRef.current = player)}
          />
        </S.VREContainer>

        <S.FrameCanvas ref={frameCanvasRef} width="1920" height="1080" />
      </S.VerticalStreamingCard>
    );
  };

  const renderHorizontalLayout = () => {
    return (
      <S.HorizontalStreamingCardContainer ref={horizontalStreamingCardRef}>
        <S.HorizontalStreamingCard padding={[18, 18]}>
          <Space direction="vertical">
            <Row justify="space-between" align="middle">
              <H3>{camera.name}</H3>
              <S.CloseButton icon={<CloseOutlined />} onClick={clearStreamingCam} />
            </Row>

            <BaseForm form={form} onClick={(e: any) => e.stopPropagation()}>
              <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <BaseForm.Item
                    name="detectionConfidence"
                    label={`${t('pages.home.detectionConfidence')} (${detectionConfidenceWatch?.toFixed(0)}%)`}
                  >
                    <Slider />
                  </BaseForm.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <BaseForm.Item
                    name="classificationConfidence"
                    label={`${t('pages.home.classificationConfidence')} (${classificationConfidenceWatch?.toFixed(
                      0,
                    )}%)`}
                  >
                    <Slider />
                  </BaseForm.Item>
                </Col>
              </Row>
            </BaseForm>
          </Space>
          <S.VREContainer>
            <VREPlayer
              key={camera.url}
              videojsOptions={videoJsOptions}
              onReady={(player: any) => (playerRef.current = player)}
            />
          </S.VREContainer>
          <S.FrameCanvas ref={frameCanvasRef} width="1920" height="1080" />
        </S.HorizontalStreamingCard>
      </S.HorizontalStreamingCardContainer>
    );
  };

  return direction === 'vertical' ? renderVerticalLayout() : renderHorizontalLayout();
};

export default StreamingCard;

import React, { useState, useEffect } from 'react';
import {Drawer, Button, Spin, Modal, message, Input, Popover} from 'antd';
import axios from 'axios';
import ROIList from './ROIList/ROIList';
import EditROIModal from './EditROI/EditROI';
import {useAppSelector} from "@app/hooks";
import {addROI, deleteROI, fetchAllROIs, editROI} from "@store/slices/UserContent/thunks";
import { useAppDispatch } from '@app/hooks';
import { Icon } from '@iconify/react';
import {useSelector} from "react-redux";
import {RootState} from "@app/store";
import {setRoiCount, setRoiPopoverVisible} from "@store/slices/UserContent/slice";
interface ROIDrawerProps {
    visible: boolean;
    onClose: () => void;
    currentZoomLevel: number;
}
import {useAuth} from "@app/context/AuthContext";

const ROIDrawer: React.FC<ROIDrawerProps> = ({ visible, onClose, currentZoomLevel }) => {
    const [ROIs, setROIs] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [editedROIName, setEditedROIName] = useState<string>('');
    const [currentROIName, setCurrentROIName] = useState<string>('');
    const mapBounds = useAppSelector((state) => state.filters.mapBounds);
    const [roiName, setRoiName] = useState("");
    const dispatch = useAppDispatch();
    const [roiUsage, setRoiUsage] = useState(0);
    const isRoiPopoverVisible = useSelector((state: RootState) => state.roi.roiPopoverVisible);
    const [isRoisChanged, setIsRoisChanged] = useState(false);
    const {currentUser} = useAuth();
    const userEmail = currentUser?.email ? currentUser.email : "";
    useEffect(() => {
        if(ROIs) {
            setRoiUsage(ROIs.length);
            dispatch(setRoiCount(ROIs.length));
        }
    }, [ROIs]);
    useEffect(() => {

        if (visible ) {
            setLoading(true);


            const source = axios.CancelToken.source();

            dispatch(fetchAllROIs({ email: userEmail, cancelToken: source.token }))
                .unwrap()
                .then(data => {
                    setROIs(data);
                    setLoading(false);
                    setIsRoisChanged(false);
                })
                .catch(err => {
                    console.error("Error fetching ROIs:", err);
                    setLoading(false);
                });


            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
    }, [visible, isRoisChanged, dispatch]);


    const handleEditROI = (name: string) => {
        setCurrentROIName(name);
        setEditedROIName(name);
        setEditModalVisible(true);
    };

    const handleDeleteROI = (name: string) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this ROI?',
            content: `ROI Name: ${name}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const resultAction = await dispatch(deleteROI({email:userEmail, roiName: name }));
                    if (deleteROI.fulfilled.match(resultAction)) {

                        const updatedROIs = ROIs.filter(roi => roi.name !== name);
                        setROIs(updatedROIs);
                        setIsRoisChanged(true);
                        message.success('ROI deleted successfully');
                    } else {

                        message.error('Failed to delete ROI: ' + resultAction.payload);
                    }
                } catch (error) {
                    console.error('Error deleting ROI:', error);
                    message.error('An unexpected error occurred');
                }
            }
        });
    };

    const handleUpdateROI = async () => {
        try {
            await dispatch(editROI({
                email: userEmail,
                currentROIName: currentROIName,
                newROIName: editedROIName
            })).unwrap();

            const updatedROIs = ROIs.map(roi =>
                roi.name === currentROIName ? { ...roi, name: editedROIName } : roi
            );

            setROIs(updatedROIs);
            setIsRoisChanged(true);


            setEditModalVisible(false);
            setEditedROIName('');
            setCurrentROIName('');
        } catch (error) {
            console.error("Error updating ROI:", error);
        }
    };
    const handleAddROI = () => {
        dispatch(setRoiPopoverVisible(true))
    };
    const handleSaveROI = () => {
        if(!mapBounds.northWest) return;
        if(!mapBounds.southEast) return;


        const roiData = {
            ZoomLevel: currentZoomLevel,
            northWestZero: mapBounds.northWest[0],
            northWestOne: mapBounds.northWest[1],
            southEastZero: mapBounds.southEast[0],
            southEastOne: mapBounds.southEast[1],
            name: roiName
        };

        dispatch(addROI({email: userEmail,  ROIData: roiData }))
            .unwrap()
            .then(() => {
                dispatch(setRoiPopoverVisible(false));
                setIsRoisChanged(true);

            })
            .catch(error => {
                console.error("Error adding ROI:", error);
            });
    };

    return (
        <>
            <Drawer
                title="Region Of Interest Management"
                placement="left"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={400}
                footer={
                    <Button
                        type="default"
                        onClick={onClose}
                        style={{ marginTop: 16 }}
                    >
                        Cancel
                    </Button>
                }
            >
                {loading ? (
                    <Spin tip="Loading ROIs..." />
                ) : (
                    <>
                        <Popover

                            content={(
                                <div>
                                    <Input
                                        placeholder="Enter ROI name"
                                        value={roiName}
                                        onChange={(e) => setRoiName(e.target.value)}
                                    />
                                    <Button type="primary" onClick={handleSaveROI} style={{ marginTop: '10px', zIndex: 100 }}>
                                        Save ROI
                                    </Button>
                                </div>
                            )}
                            title="Add ROI"
                            trigger="click"
                            visible={isRoiPopoverVisible}
                            onVisibleChange={(visible) => dispatch(setRoiPopoverVisible(visible))}
                            overlayInnerStyle={{ backgroundColor: '#f0f0f0'}}
                        >
                            <Button
                                type="primary"
                                onClick={handleAddROI}
                                disabled={roiUsage >= 5}
                                style={{ marginBottom: '20px', zIndex: 100 }}
                            >
                                <Icon icon="material-symbols:add" />
                            </Button>
                        </Popover>

                        <ROIList
                            ROIs={ROIs}
                            handleEditROI={handleEditROI}
                            handleDeleteROI={handleDeleteROI}

                        />
                    </>
                )}
            </Drawer>

            <EditROIModal
                visible={editModalVisible}
                editedROIName={editedROIName}
                setEditedROIName={setEditedROIName}
                handleUpdateROI={handleUpdateROI}
                onCancel={() => {
                    setEditModalVisible(false);
                    setEditedROIName('');
                    setCurrentROIName('');
                }}
            />
        </>
    );

};

export default ROIDrawer;

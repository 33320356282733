import React from 'react';
import * as S from './CustomContent.style';

interface CustomContentProps {
    children: React.ReactNode;
}

const CustomContent: React.FC<CustomContentProps> = ({ children }) => {
    return (
        <S.CustomContent style={{ padding: '0 24px', minHeight: 280 }}>
            {children}
        </S.CustomContent>
    );
};

export default CustomContent;

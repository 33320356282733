import { ChartType, TrackAnalyticQueryFields } from '@common/index';
import { H3, Space } from '@components/index';
import { Row, Spin } from 'antd';
import React, { useState } from 'react';
import { AnalyticDataChart } from '../AnalyticDataChart';
import * as S from './AnalyticDataCard.style';

interface IAnalyticDataCardProps {
  title: string;
  queryBaseUrl?: string;
  chartType: ChartType;
  trackQueryField: TrackAnalyticQueryFields | any;
  yAxisExtractor?: (d: any) => any;
  sortDataEnabled?: boolean;
  selectedFieldsExtractor?: (d:any) => any;
  fieldFilterName: string;
}

export const AnalyticDataCard: React.FC<IAnalyticDataCardProps> = ({
  queryBaseUrl,
  title,
  chartType,
  trackQueryField,
  yAxisExtractor,
  selectedFieldsExtractor,
  fieldFilterName,
  sortDataEnabled
}) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  return (
    <S.Card padding={[15, 15]}>
      <Space direction="vertical">
        <Row justify="space-between" align="middle">
          <H3>{title}</H3>
          {isDataLoading && <Spin size="small" />}
        </Row>
        <AnalyticDataChart
          type={chartType}
          queryBaseUrl={queryBaseUrl}
          trackQueryField={trackQueryField}
          yAxisExtractor={yAxisExtractor}
          selectedFieldsExtractor={selectedFieldsExtractor}
          fieldFilterName={fieldFilterName}
          setLoading={setIsDataLoading}
          sortDataEnabled={sortDataEnabled}
        />
      </Space>
    </S.Card>
  );
};

export default AnalyticDataCard;

import {AnalyticData, ChartType, Countries, SpeedFilter, TrackAnalyticQueryFields} from '@app/common';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AnalyticDataCard} from '../AnalyticDataCard';
import {Col, Row} from 'antd';


const AnalyticPanel: React.FC = () => {
    const {t} = useTranslation();

    const flagFieldExtractor = (d: string) => Object.keys(Countries)[Object.values(Countries).indexOf(d as Countries)];

    const speedFieldExtractor = (d: string) => {
        const speeds = d.split('-');
        const speedFilter: SpeedFilter = {
            gt: parseInt(speeds[0], 10),
            lt: isNaN(parseInt(speeds[1], 10)) ? undefined : parseInt(speeds[1], 10),
        };

        if (isNaN(parseInt(speeds[0], 10))) {
            delete speedFilter.gt;
        }

        if (isNaN(parseInt(speeds[1], 10))) {
            delete speedFilter.lt;
        }

        return speedFilter;
    };
    return (
        <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <AnalyticDataCard
                    title={t('pages.home.flag')}
                    chartType={ChartType.HORIZONTAL_BAR}
                    trackQueryField={TrackAnalyticQueryFields.FLAG}
                    yAxisExtractor={(d: AnalyticData) => Countries[d.key as keyof typeof Countries]}
                    selectedFieldsExtractor={flagFieldExtractor}
                    fieldFilterName={"flags"}
                />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <AnalyticDataCard
                    title={t('pages.home.vesselType')}
                    chartType={ChartType.HORIZONTAL_BAR}
                    trackQueryField={TrackAnalyticQueryFields.VESSEL_TYPE}
                    fieldFilterName={"vesselTypes"}
                />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <AnalyticDataCard
                    title={t('pages.home.destination')}
                    chartType={ChartType.HORIZONTAL_BAR}
                    trackQueryField={TrackAnalyticQueryFields.DESTINATION}
                    fieldFilterName={"destinations"}

                />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <AnalyticDataCard
                    title={t('pages.home.speed')}
                    chartType={ChartType.HORIZONTAL_BAR}
                    trackQueryField={{"Speed": [{"lt": 5}, {"gt": 5, "lt": 10}, {"gt": 10}]}}
                    selectedFieldsExtractor={speedFieldExtractor}
                    sortDataEnabled={false}
                    fieldFilterName={"speeds"}
                />
            </Col>


        </Row>
    );
};

export default AnalyticPanel;

import {
    fetchLatestMapCenter,
    fetchLatestZoomlevel,
    persistLatestMapCenter,
    persistLatestZoomLevel,
    persistMapBounds,
} from '@app/services';
import L, {LatLng} from 'leaflet';
import {useEffect} from 'react';
import {useMap} from 'react-leaflet';

const LeafletPositionTrackerLayer = () => {
    const map = useMap();

    useEffect(() => {
        if (map) {
            const latestZoomLevel: number | null = fetchLatestZoomlevel();
            const latestMapCenter: { lat: number | undefined; lon: number | undefined } = fetchLatestMapCenter();

            if (latestZoomLevel && latestMapCenter.lat && latestMapCenter.lon) {
                map.setView(new L.LatLng(latestMapCenter.lat, latestMapCenter.lon), latestZoomLevel);
            }
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            map.on('moveend', function (e: any) {
                e?.target?._zoom && persistLatestZoomLevel(e.target._zoom);

                const center: LatLng = map.getCenter();
                const bounds: L.LatLngBounds = map.getBounds();
                const mapBounds: {
                    northWest: [number, number] | null;
                    southEast: [number, number] | null;
                } = {
                    northWest: [bounds.getNorth(), bounds.getWest()],
                    southEast: [bounds.getSouth(), bounds.getEast()]
                };

                persistLatestMapCenter(center.lat, center.lng);
                persistMapBounds(mapBounds);
            });

        }
        return () => {
            map.off('moveend');
        };
    }, []);

    return <div></div>;
};

export default LeafletPositionTrackerLayer;

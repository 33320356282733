import MariticsLogo from '@assets/images/mariticsLogo.svg';
import React from 'react';
import * as S from './AppLogo.styles';

interface IAppLogoProps {
  margin?: string;
  height?: string;
  width?: string;
}

const AppLogo: React.FC<IAppLogoProps> = ({ margin, width, height }) => {
  return (
    <S.AppLogoContainer margin={margin}>
      <S.AppLogoImage height={height} width={width} src={MariticsLogo} />
    </S.AppLogoContainer>
  );
};

export default AppLogo;

import { FONT_SIZE } from '@common/constants';
import { Menu, Row } from 'antd';
import styled from 'styled-components';
import { Dropdown as BaseDropdown } from '../Dropdown';

export const CardMenu = styled(Menu)`
  box-shadow: var(--box-shadow);
  z-index: 999;
`;

export const MenuItem = styled(Menu.Item)`
  font-size: ${FONT_SIZE.xs};
`;

export const HeaderRow = styled(Row)`
  align-items: center;
`;

export const Dropdown = styled(BaseDropdown)`
  cursor: pointer;
`;

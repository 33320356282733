import { BASE_COLORS, Track } from '@app/common';
import { useAppSelector } from '@app/hooks';
import chroma from 'chroma-js';
import L from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

const LeafletTrackHistoryLayer: React.FC = () => {
  const map = useMap();
  const redrawing = useRef<boolean>(false);

  const mapTracksHistory: { [key: string]: Track[] } | null = useAppSelector(
    (state) => state.tracks.mapTrackHistories.data,
  );

  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
  const selectedMapTrackHistory: Track[] | null =
    selectedMapTrackId && mapTracksHistory ? mapTracksHistory[selectedMapTrackId] : null;

  const minSpeedOfHistory = selectedMapTrackHistory && selectedMapTrackHistory.length > 0
      ? selectedMapTrackHistory.reduce((min, t) => t.speed < min ? t.speed : min, selectedMapTrackHistory[0].speed)
      : 0;
  const maxSpeedOfHistory = selectedMapTrackHistory && selectedMapTrackHistory.length > 0
      ? selectedMapTrackHistory.reduce((max, t) => t.speed > max ? t.speed : max, selectedMapTrackHistory[0].speed)
      : 0;

  const createPolylinePositionsForSelectedMarker = () => {
    if (!selectedMapTrackHistory) return null;

    const polylinePositions: {
      fromPosition: [number, number];
      toPosition: [number, number];
      speed: number;
    }[] = [];


    for (let idx = 0; idx < selectedMapTrackHistory.length - 1; idx++) {
      const latestTrack = selectedMapTrackHistory[idx];
      const trackBefore = selectedMapTrackHistory[idx + 1];

      polylinePositions.push({
        fromPosition: [latestTrack.location.lat, latestTrack.location.lon],
        toPosition: [trackBefore.location.lat, trackBefore.location.lon],
        speed: latestTrack.speed,
      });
    }

    return polylinePositions;
  };

  useEffect(() => {
    let polylines: L.Polyline[] = [];

    const markPolylinesOnMap = () => {
      const polylinePositions = createPolylinePositionsForSelectedMarker();

      polylinePositions &&
      polylinePositions.forEach((p) => {
        const colorScale = chroma
            .scale([BASE_COLORS.green, BASE_COLORS.orange, BASE_COLORS.red])
            .domain([minSpeedOfHistory, maxSpeedOfHistory]);
        const color = colorScale(p.speed).hex();

        const polylineObject = new L.Polyline([p.fromPosition, p.toPosition], {
          color: color,
          opacity: 0.7,
        });
        polylineObject.addTo(map);
        polylines.push(polylineObject);
      });
    };

    redrawing.current = false;
    markPolylinesOnMap();

    return () => {
      redrawing.current = true;
      polylines?.forEach((p) => {
        map.removeLayer(p);
      });
    };
  }, [mapTracksHistory, selectedMapTrackId]);;

  return <div></div>;
};

export default LeafletTrackHistoryLayer;

import selectedMapElementIcon from '@assets/images/selectedMapElementIcon.svg';
import { BASE_COLORS, Track } from '@common/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { setSelectedMapTrackId } from '@store/slices/Tracks/slice';
import { getVesselTypeColor } from '@utils/index';
import L from 'leaflet';
import 'leaflet-tracksymbol';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';

const LeafletTrackSymbolLayer: React.FC = () => {
  const map = useMap();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const redrawing = useRef<boolean>(false);
  const tracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
  const mapTracksHistory: { [key: string]: Track[] } | null = useAppSelector(
      (state) => state.tracks.mapTrackHistories.data,
  );

  const assingClickHandlerToTrack = (trackMarker: any) => {
    const track: Track | undefined = trackMarker?.options?.track;

    trackMarker.on('click', () => {
      if (track && track.mmsi === selectedMapTrackId) {
        dispatch(setSelectedMapTrackId(undefined));
      } else if (track) {
        dispatch(setSelectedMapTrackId(track.mmsi));
      }
    });
  };

  useEffect(() => {
    let trackMarkers: L.Layer[] = [];
    let selectedMarkerContainer: L.Layer;
    let selectedElement: L.ImageOverlay;

    const markTracksOnMap = async () => {
      if (!tracks) return;

      for (const mmsi of Object.keys(tracks)) {
        const t: Track = tracks[mmsi];

        const latlng = L.latLng(t.location.lat, t.location.lon);
        const speed = t.speed;
        const course = (t.course * Math.PI) / 180.0;
        const heading = (t.course * Math.PI) / 180.0;

        const opacity = selectedMapTrackId ? (t.mmsi === selectedMapTrackId ? 1.0 : 0.1) : 1.0;
        const trackMarker = (L as any).trackSymbol(latlng, {
          isTrackLayer: true,
          track: t,
          trackId: t.mmsi,
          fill: true,
          fillColor: getVesselTypeColor(t.vesselType),
          fillOpacity: opacity,
          stroke: true,
          color: BASE_COLORS.black,
          opacity: 1.0,
          weight: 1.0,
          speed: speed,
          course: course,
          heading: heading,
        });

        if (selectedMapTrackId === trackMarker.options?.track?.mmsi) {
          const squareSize = 0.0035 * Math.pow(2, 13 - map.getZoom());

          const bounds = L.latLngBounds(
            [
              trackMarker.getBounds().getNorthEast().lat + squareSize,
              trackMarker.getBounds().getNorthEast().lng - squareSize,
            ],
            [
              trackMarker.getBounds().getSouthWest().lat - squareSize,
              trackMarker.getBounds().getSouthWest().lng + squareSize,
            ],
          );

          map.createPane('selectionImagePane');
          map.getPane('selectionImagePane')!.style.zIndex = '401';

          selectedElement = L.imageOverlay(selectedMapElementIcon, bounds, {
            pane: 'selectionImagePane',
          }).addTo(map);
        }

        trackMarker.addTo(map);

        assingClickHandlerToTrack(trackMarker);
        trackMarkers.push(trackMarker);
      }
    };

    redrawing.current = false;
    markTracksOnMap();

    return () => {
      redrawing.current = true;
      trackMarkers?.map((tm) => {
        map.removeLayer(tm);
      });
      selectedMarkerContainer && map.removeLayer(selectedMarkerContainer);
      selectedElement && map.removeLayer(selectedElement);
    };
  }, [map, tracks, selectedMapTrackId, mapTracksHistory]);

  return null;
};

export default LeafletTrackSymbolLayer;

import styled from 'styled-components';
export const ROIs = styled.h1`
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 20px;
  /* Add any additional styling if needed */
`;
export const roiOverview = styled.h1`
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 0px;
  /* Add any additional styling if needed */
`;

export const AddNewROIButton = styled.button`
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #40a9ff;
    }
`;
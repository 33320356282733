import React from 'react';
import { Modal, Input } from 'antd';

interface FleetAddModalProps {
    isVisible: boolean;
    onOk: () => void;
    onCancel: () => void;
    newFleetName: string;
    newFleetColor: string;
    onFleetNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onColorChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FleetAddModal: React.FC<FleetAddModalProps> = ({
                                                         isVisible,
                                                         onOk,
                                                         onCancel,
                                                         newFleetName,
                                                         newFleetColor,
                                                         onFleetNameChange,
                                                         onColorChange
                                                     }) => {
    return (
        <Modal
            title="Add New Fleet"
            visible={isVisible}
            onOk={onOk}
            onCancel={onCancel}
            okText="Add Fleet"
            cancelText="Cancel"
        >
            <Input
                placeholder="Enter fleet name"
                value={newFleetName}
                onChange={onFleetNameChange}
            />
            <div style={{ marginTop: '10px' }}>
                <label htmlFor="colorPicker">Choose color:</label>
                <input
                    id="colorPicker"
                    type="color"
                    value={newFleetColor}
                    onChange={onColorChange}
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </Modal>
    );
};

export default FleetAddModal;

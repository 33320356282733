import React, {useState} from 'react';
import {List, Button, Tooltip, Modal, Popover} from 'antd';
import {DeleteOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import * as S from './ROIList.style';
import {useAppDispatch, useAppSelector} from '@app/hooks';
import {useSelector} from 'react-redux';
import {RootState} from '@app/store';
import ROIUsagePercentage from '../ROIUsagePercentage/ROIUsagePercentage';
import {getROI} from '@store/slices/UserContent/thunks';
import {setRoiDrawerVisible, setRoiPopoverVisible} from "@store/slices/UserContent/slice";
import {useNavigate} from 'react-router-dom';
import {Icon} from "@iconify/react";
import {RegionOfInterest} from "@app/common";
import { useTranslation } from 'react-i18next';
interface ROIListProps {
    ROIs: RegionOfInterest[];
    handleEditROI: (oldName: string, newName: string) => void;
    handleDeleteROI: (name: string) => void;
    onROINameClick: (name: string) => void;
}

const ROIList: React.FC<ROIListProps> = ({ROIs, handleEditROI, handleDeleteROI}) => {
    const navigate = useNavigate();
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [editingROI, setEditingROI] = useState<{ oldName: string; newName: string } | null>(null);
    const dispatch = useAppDispatch();
    const roiCount = useSelector((state: RootState) => state.roi.roiCount);
    const { t } = useTranslation();
    const [isLimitReached, setIsLimitReached] = useState(false);
    const userEmail = useAppSelector((state) => state.roi.email);
    const showDeleteConfirm = (index: number) => {
        setDeleteIndex(index);
    };

    const handleDeleteCancel = () => {
        setDeleteIndex(null);
    };

    const handleDeleteConfirm = () => {
        if (deleteIndex !== null) {
            handleDeleteROI(ROIs[deleteIndex].name);
            setDeleteIndex(null);
        }
    };

    const handleEdit = (oldName: string) => {
        setEditingROI({oldName, newName: oldName});
    };

    const handleEditConfirm = () => {
        if (editingROI) {
            handleEditROI(editingROI.oldName, editingROI.newName);
            setEditingROI(null);
        }
    };

    const handleEditCancel = () => {
        setEditingROI(null);
    };

    const handleROINameClicked = async (name: string) => {
        try {

            await dispatch(getROI({email: userEmail, roiName: name})).unwrap();
            navigate('/app'); // ROI başarıyla alındığında yönlendirme yap

        } catch (error) {
            console.error("Error getting ROI:", error);
        }
    };

    const handleAddROIClick = () => {
        if (roiCount >= 5) {
            setIsLimitReached(true);
        } else {
            try {

                dispatch(setRoiDrawerVisible(true));
                dispatch(setRoiPopoverVisible(true));
                navigate('/app');


            } catch (err) {

            }
        }
    };
    const handleLimitReachedModalOk = () => {
        setIsLimitReached(false);
    };


    return (
        <>
            <S.ROIs>My Region Of Interests</S.ROIs>
            <ROIUsagePercentage roiUsage={roiCount}/>
            <Popover content="Add New ROI" placement="top">
                <S.AddNewROIButton onClick={handleAddROIClick} style={{marginBottom: 16}}>
                    <Icon icon="material-symbols:add" width={20} height={18}/>
                </S.AddNewROIButton>
            </Popover>
            <List
                header={<S.roiOverview>Region Of Interests Overview</S.roiOverview>}
                bordered
                dataSource={ROIs}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Tooltip title="View on Map" key={`view-${item.name}`}>
                                <Button
                                    type="text"
                                    icon={<EyeOutlined/>}
                                    onClick={() => handleROINameClicked(item.name)} // Handle "View on Map" click
                                />
                            </Tooltip>,
                            <Tooltip title="Edit ROI" key={`edit-${item.name}`}>
                                <Button
                                    type="text"
                                    icon={<EditOutlined style={{color: 'blue'}}/>}
                                    onClick={() => handleEdit(item.name)}
                                />
                            </Tooltip>,
                            <Tooltip title="Delete ROI" key={`delete-${item.name}`}>
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{color: 'red'}}/>}
                                    onClick={() => showDeleteConfirm(ROIs.findIndex(roi => roi.name === item.name))}
                                />
                            </Tooltip>
                        ]}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {item.name}
                        </div>
                    </List.Item>
                )}
                style={{marginTop: 16}}
            />

            <Modal
                title="Confirm Deletion"
                visible={deleteIndex !== null}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>{t('pages.account.confirm_deletion_roi')}</p>
            </Modal>

            <Modal
                title="Edit ROI"
                visible={!!editingROI}
                onOk={handleEditConfirm}
                onCancel={handleEditCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <p>{t('pages.account.ask_new_roi_name')}</p>
                <input
                    type="text"
                    value={editingROI?.newName || ''}
                    onChange={(e) => setEditingROI({...editingROI!, newName: e.target.value})}
                />
            </Modal>
            <Modal
                title="ROI Limit Reached"
                visible={isLimitReached}
                onOk={handleLimitReachedModalOk}

                okText="OK"
                closable={false}
                footer={[
                    <Button key="ok" type="primary" onClick={handleLimitReachedModalOk}>
                        OK
                    </Button>
                ]}
            >
                <p>{t('pages.account.roi_limit_reached_long')}</p>
            </Modal>
        </>
    );
};

export default ROIList;

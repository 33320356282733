import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { BORDER_RADIUS, FONT_SIZE } from '@app/common';
import { Badge, Card } from '@app/components';
import styled from 'styled-components';

export const MapLegendCardContainer = styled.div`
  z-index: 401;
  position: absolute;
  right: 0;
  bottom: 180px;
  padding: 0.5rem;
`;

export const MapLegendCard = styled(Card)`
  width: 100%;
  min-width: 120px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: ${BORDER_RADIUS};

  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
`;

export const VesselBadge = styled(Badge)`
  display: flex;
  align-items: center;

  .ant-badge-status-dot {
    width: 4px;
    height: 4px;
  }

  .ant-badge-status-text {
    font-size: ${FONT_SIZE.xxxs};
  }
`;

export const CustomDownOutlined = styled(DownOutlined)`
  font-size: ${FONT_SIZE.x4s};
  padding: 0.1rem;
`;

export const CustomUpOutlined = styled(UpOutlined)`
  padding: 0.1rem;
  font-size: ${FONT_SIZE.x4s};
`;

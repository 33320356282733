import axios from 'axios';
import {readBearerToken} from "@app/services";

const bearerInterceptor = (config: any) => {
    const token = readBearerToken();
    if (token) {
        const parsedToken = token.includes('"') ? JSON.parse(token) : token;
        config.headers.Authorization = `Bearer ${parsedToken}`;
    }
    return config;
};

// const unauthorizedInterceptor = (data: any) => {
//   if (data?.response?.status === 403) {
//     clearBearerToken();
//     return Promise.reject(data);
//   }
//   return Promise.resolve(data);
// };

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(bearerInterceptor);

export const createCancelToken = () => {
    return axios.CancelToken.source();
};
export const isCancel = axios.isCancel;

// instance.interceptors.response.use(unauthorizedInterceptor);

export default axiosInstance;
export type {AxiosRequestConfig, CancelTokenSource, CancelToken} from 'axios';

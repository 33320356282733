import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setFilters } from '@app/store/slices/Filters/slice';
import { LatLngBounds} from 'leaflet';
import React, { useEffect, useCallback, useState } from 'react';
import { useMap } from 'react-leaflet';
import {clearCurrentRegionOfInterest} from "@store/slices/UserContent/slice";
import 'leaflet/dist/leaflet.css';



const LeafletGeoExtractorLayer: React.FC = () => {
    const map = useMap();
    const dispatch = useAppDispatch();
    const filters = useAppSelector((state) => state.filters);
    const [showROIName, setShowROIName] = useState(false);
    const currentROI = useAppSelector((state) => state.roi.currentRegionOfInterest);
    const [roiName, setRoiName] = useState("");

    const flyToMapView = useCallback(() => {
        if (map && currentROI) {

            const centerZero = (currentROI.northWestZero + currentROI.southEastZero) / 2;
            const centerOne = (currentROI.northWestOne + currentROI.southEastOne) / 2;
            const center: [number, number] = [centerZero, centerOne];
            const zoom: number = currentROI.ZoomLevel;

            setRoiName(currentROI.name);

            map.flyTo(center, zoom, { duration: 1.5 });
            setShowROIName(true);
            setTimeout(() => {
                setShowROIName(false);
            }, 2500);
        }
    }, [map, currentROI]);

    useEffect(() => {
        if (currentROI) {
            flyToMapView();
        }
    }, [currentROI, flyToMapView]);

    useEffect(() => {
        const handleMapMove = () => {
            if (map) {
                const bounds: LatLngBounds = map.getBounds();

                dispatch(
                    setFilters({
                        ...filters,
                        mapBounds: {
                            northWest: [bounds.getNorthWest().lat, bounds.getNorthWest().lng],
                            southEast: [bounds.getSouthEast().lat, bounds.getSouthEast().lng],
                        },
                    })
                );

                dispatch(clearCurrentRegionOfInterest());

            }
        };

        if (map) {
            map.on('moveend', handleMapMove);
        }

        return () => {
            if (map) {
                map.off('moveend', handleMapMove);
            }
        };
    }, [map, filters, dispatch]);

    return (
        <>
            {showROIName && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(128, 128, 128, 0.8)',  // gri arka plan
                    padding: '20px',
                    borderRadius: '10px',
                    zIndex: 1000,
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                }}>
                    {roiName}
                </div>
            )}
        </>
    );
};

export default LeafletGeoExtractorLayer;

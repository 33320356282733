import { BASE_COLORS } from '@app/common';
import { ITheme } from '@common/interfaces';
import { shadeColor } from '@utils/index';

export const darkColorsTheme: ITheme = {
  primaryColor: '#339CFD',
  primary1Color: '#7568f6',
  primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)',
  secondaryColor: '#0072DD',
  backgroundColor: '#25284B',
  secondaryBackgroundColor: '#1c2137',
  secondaryBackgroundSelectedColor: shadeColor('#1c2137', -5),
  textMainColor: '#ffffff',
  textLightColor: '#9A9B9F',
  textSuperLightColor: '#444',
  textSecondaryColor: '#ffffff',
  textDarkColor: '#404040',
  subtextColor: '#a9a9a9',
  spinnerBaseColor: '#339CFD',
  scrollColor: '#797C9A',
  borderColor: '#ffffff',
  borderBaseColor: '#a9a9a9',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: 'none',
  boxShadowDarker: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
  boxShadowHover: 'none',
  notificationSuccessColor: '#EFFFF4',
  notificationPrimaryColor: '#D7EBFF',
  notificationWarningColor: '#FFF4E7',
  notificationErrorColor: '#FFE2E2',
  headingColor: BASE_COLORS.white,
  disableColor: '#7e7e7e',
  disabledBgColor: '#1c2137',
  layoutBodyBgColor: '#1e2142',
  layoutHeaderBgColor: '#1e2142',
  layoutSiderBgColor: '#121430',
  inputPlaceholderColor: 'rgba(255, 255, 255, 0.5)',
  itemHoverBgColor: '#1c2137',
  backgroundBaseColor: '#1c2137',
  avatarBgColor: '#1c2137',
  alertTextColor: '#000',
  iconColor: '#a9a9a9',
  iconHoverColor: '#ffffff',
  errorColor: '#FF5252',
  warningColor: '#FFB765',
  successColor: '#57D682',
  chartBarBackgroundColor: 'rgba(180, 180, 180, 0.2)',
};

import { Severity } from '@common/index';
import { defineColorBySeverity } from '@utils/index';
import { Badge as AntBadge } from 'antd';
import styled from 'styled-components';

interface BadgeProps {
  severity?: Severity;
}

export const Badge = styled(AntBadge)<BadgeProps>`
  color: inherit;

  & .ant-badge-count {
    background: ${(props) => defineColorBySeverity(props.severity)};
  }
`;

import { HeatmapEntry } from '@app/common';
import { useAppSelector } from '@app/hooks';
import L from 'leaflet';
import 'leaflet.heat';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const LeafletHeatmapLayer: React.FC = () => {
  const map = useMap();
  const heatmapEntries: HeatmapEntry[] | null = useAppSelector((state) => state.tracks.heatmapEntries.data);

  useEffect(() => {
    const maxCount: number = heatmapEntries
      ? heatmapEntries.reduce((maxCount, entry) => Math.max(maxCount, entry.count), 0)
      : 1;
    const totalCount: number = heatmapEntries ? heatmapEntries.reduce((acc, entry) => acc + entry.count, 0) : 1;

    const points = heatmapEntries
      ? heatmapEntries.map((entry) => {
          return [entry.lat, entry.lon, entry.count / maxCount];
        })
      : [];

    const heatmapElements = (L as any)
      .heatLayer(points, {
        radius: 30,
        minOpacity: 0.3,
      })
      .addTo(map);

    return () => {
      heatmapElements && map.removeLayer(heatmapElements);
    };
  }, [heatmapEntries]);

  return null;
};

export default LeafletHeatmapLayer;

import styled from 'styled-components';
import { Switch } from 'antd';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.span`
    margin-right: 8px;
    color: #000;
    font-size: 14px;
`;

export const StyledSwitch = styled(Switch)`
    .ant-switch-checked {
        background-color: green; // Custom color when switch is on
    }
    .ant-switch {
        background-color: gray; // Custom color when switch is off
    }
`;

import React, {useEffect, useState} from 'react';
import * as S from './AccountOverview.style';
import {User} from "@app/common";
import {useAuth} from "@app/context/AuthContext";
import {useAppSelector} from "@app/hooks";

interface AccountOverviewProps {
    user: User;
    onViewFleets: () => void;
    onViewROIs: () => void;
}

const AccountOverview: React.FC<AccountOverviewProps> = ({ user, onViewFleets, onViewROIs }) => {
    const { currentUser } = useAuth();
    const [displayName, setDisplayName] = useState<string | null>(currentUser?.displayName || null);
    const newName = useAppSelector(state => state.roi.fullName)

    useEffect(() => {
        if(newName) {
            setDisplayName(newName)
        }
        else{
            setDisplayName(currentUser?.displayName || null);
        }
    }, [newName, currentUser]);
    if (!user) {
        return <div>No user data available</div>;
    }
    return (
        <S.Container>
            <S.Title>Account Overview</S.Title>

            <S.InfoRow>
                <S.Label>Your Name:</S.Label>
                <S.Value>{displayName}</S.Value>
            </S.InfoRow>

            <S.InfoRow>
                <S.Label>Your Email:</S.Label>
                <S.Value>{user.email}</S.Value>
            </S.InfoRow>

            <S.InfoRow>
                <S.Label>Account Created:</S.Label>
                <S.Value>{user.accountCreatedAt}</S.Value>
            </S.InfoRow>

            <S.ButtonContainer>
                <S.Label>Fleet overview:</S.Label>
                <S.CustomButton onClick={onViewFleets}>View my fleets</S.CustomButton>
            </S.ButtonContainer>

            <S.ButtonContainer>
                <S.Label>ROI overview:</S.Label>
                <S.CustomButton onClick={onViewROIs}>View my ROIs</S.CustomButton>
            </S.ButtonContainer>
        </S.Container>
    );
};

export default AccountOverview;

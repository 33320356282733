import React from 'react';
import {Modal, Input} from 'antd';

interface AddFleetModalProps {
    visible: boolean;
    newFleetName: string;
    setNewFleetName: (name: string) => void;
    newFleetColor: string;
    setNewFleetColor: (color: string) => void;
    handleAddFleetConfirm: () => void;
    onCancel: () => void;
}

const AddFleetModal: React.FC<AddFleetModalProps> = ({
                                                         visible,
                                                         newFleetName,
                                                         setNewFleetName,
                                                         newFleetColor,
                                                         setNewFleetColor,
                                                         handleAddFleetConfirm,
                                                         onCancel
                                                     }) => (
    <Modal
        title="Add New Fleet"
        visible={visible}
        onOk={handleAddFleetConfirm}
        onCancel={onCancel}
        okText="Add"
        cancelText="Cancel"
    >
        <Input
            placeholder="Fleet Name"
            value={newFleetName}
            onChange={(e) => setNewFleetName(e.target.value)}
        />
        <div style={{marginTop: '10px'}}>
            <label htmlFor="colorPicker">Choose color:</label>
            <input
                id="colorPicker"
                type="color"
                value={newFleetColor}
                onChange={(e) => setNewFleetColor(e.target.value)}
                style={{marginLeft: '10px'}}
            />
        </div>
    </Modal>
);

export default AddFleetModal;

import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
interface LimitReachedModalProps {
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
}

const LimitReachedModal: React.FC<LimitReachedModalProps> = ({
                                                                 visible,
                                                                 onOk,
                                                                 onCancel
                                                             }) => {
const { t } = useTranslation();
return (
    <Modal
        title="Limit Reached"
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText="OK"
        cancelText="Cancel"
    >
        <p>{t("pages.account.fleet_limit_reached")}</p>
    </Modal>
);
};


export default LimitReachedModal;

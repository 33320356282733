import React, {useEffect, useRef, useState} from 'react';
import * as S from './FleetView.style';
import {Button, message, Modal, notification, Tooltip} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {ColumnType} from 'antd/es/table';
import {CancelTokenSource, createCancelToken} from "@api/index"
import {fetchMapTracks} from '@store/slices/Tracks/thunks';
import moment from 'moment';
import {Countries, FetchTracksForm, IMapFilters} from '@app/common';
import {useAppDispatch, useAppSelector} from '@app/hooks';

import {Vessel, Fleet} from "@app/common";



interface FleetViewProps {
    fleet: Fleet | null;
    onBack: () => void;
    onDeleteVessel: (fleetName: string, vesselMmsi: string, emailToFetch: string) => Promise<void>;
}

const FleetView: React.FC<FleetViewProps> = ({ fleet, onBack, onDeleteVessel }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [vesselDetails, setVesselDetails] = useState<{ [key: string]: Vessel }>({});
    const showTrackCancelTokenRef = useRef<CancelTokenSource | null>(null);
    const emailToFetch = "contact.ogunisik@gmail.com";
    const dispatch = useAppDispatch();
    const mapFilters: IMapFilters = useAppSelector((state) => state.filters);
    const formatPosition = (position: string | undefined) => {
        if (!position) return 'N/A';


        const [latLabel, lonLabel] = position.split('\n');

        return (
            <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Latitude: </span>{latLabel}
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Longitude: </span>{lonLabel}
                </div>
            </div>
        );
    };
    const formatLastUpdatedTime = (time: string | undefined) => {
        if (!time) return 'N/A';
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    };

    const getVesselFlag = (countryCode: string | undefined) => {
        const unknownFlagOutput = (
            <Tooltip title="Unknown">
                <S.UnknownFlag />
            </Tooltip>
        );

        if (!countryCode) {
            return unknownFlagOutput;
        }

        const countryName = Countries[countryCode as keyof typeof Countries];

        return countryName ? (
            <Tooltip title={countryName}>
                <S.CountryFlag svg countryCode={countryCode} />
            </Tooltip>
        ) : (
            unknownFlagOutput
        );
    };

    const funct = (fleet) => {
        if (fleet) {
            const mmsiList = fleet.vessels.map(vessel => vessel.mmsi);


            if (showTrackCancelTokenRef.current) {
                showTrackCancelTokenRef.current.cancel('Operation canceled due to new request.');
            }


            showTrackCancelTokenRef.current = createCancelToken();

            if (mmsiList && mmsiList.length > 0) {
                moment(mapFilters.timeRange[1]).subtract(15, 'minutes');
                const OneYearAgo = moment(mapFilters.timeRange[1]).subtract(1, 'year');

                const fetchMapTracksForm: FetchTracksForm = {

                    timeRange: [OneYearAgo.toISOString(), mapFilters.timeRange[1]],
                    geoBox: [[89.9, -179.9], [-89.9, 179.9]],

                    mmsi: mmsiList

                };

                if (showTrackCancelTokenRef.current) {
                    showTrackCancelTokenRef.current.cancel('Operation canceled due to new request.');
                }

                showTrackCancelTokenRef.current = createCancelToken();

                dispatch(fetchMapTracks({filter: fetchMapTracksForm}))

                    .unwrap()
                    .then((data) => {

                        if (!data) {
                            console.error("No data returned from fetchMapTracks");
                            return;
                        }


                        const updatedVesselDetails: { [key: string]: Vessel } = {};

                        mmsiList.forEach((mmsi) => {
                            if (data[mmsi] ) {
                                const latestData = data[mmsi]; // En son alınan verinin ilk elemanı
                                updatedVesselDetails[mmsi] = {
                                    mmsi: latestData.mmsi,
                                    imo: latestData.imo,
                                    name: latestData.name,
                                    vesselType: latestData.vesselType,
                                    flag: latestData.flag,
                                    lastUpdateTime: latestData.timestamp,
                                    position: `${latestData.location.lat.toFixed(4)}\n${latestData.location.lon.toFixed(4)}`,
                                };

                            } else {
                                console.warn(`No data found for MMSI: ${mmsi}`);
                            }
                        });

                        setVesselDetails(updatedVesselDetails);
                    })
                    .catch((error) => {
                        console.error('Error fetching vessel details:', error);
                    });
            } else {
                console.error("MMSI list is empty or undefined");
            }
        }
    }

    useEffect(() => {
        funct(fleet);
    }, [fleet]);


    const handleDeleteVessel = (vesselMmsi: string) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this vessel?',
            content: `MMSI: ${vesselMmsi}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                setLoading(true);
                try {
                    await onDeleteVessel(fleet?.name ?? '', vesselMmsi, emailToFetch);
                    message.success('Vessel deleted successfully');
                } catch (error) {
                    console.error('Error deleting vessel:', error);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to delete vessel',
                    });
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const transformedVessels = fleet
        ? fleet.vessels.map(vessel => {
            const details = vesselDetails[vessel.mmsi] || {};
            return {
                mmsi: vessel.mmsi,
                imo: details.imo,
                name: details.name,
                vesselType: details.vesselType,
                flag: getVesselFlag(details.flag),
                lastUpdateTime: formatLastUpdatedTime(details.lastUpdateTime),
                position: formatPosition(details.position),
                actions: (
                    <Tooltip title="Delete Vessel">
                        <Button
                            type="text"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            onClick={() => handleDeleteVessel(vessel.mmsi)}
                            loading={loading}
                            style={{ marginLeft: 8 }}
                        />
                    </Tooltip>
                ),
            };
        })
        : [];


    const columns: ColumnType<any>[] = [
        { title: 'MMSI Number', dataIndex: 'mmsi', key: 'mmsi' },
        { title: 'IMO', dataIndex: 'imo', key: 'imo' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Vessel Type', dataIndex: 'vesselType', key: 'vesselType' },
        {
            title: 'Flag',
            dataIndex: 'flag',
            key: 'flag',
            render: (flag) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {flag}
                </div>
            ),
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: (position) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {position}
                </div>
            ),
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdateTime',
            key: 'lastUpdateTime',
            render: (lastUpdateTime) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {lastUpdateTime}
                </div>
            ),
        },
        {
            title: 'Delete Vessel',
            key: 'actions',
            render: (_, record) => record.actions,
        },
    ];


    return fleet ? (
        <div>
            <S.FleetName>Fleet: {fleet.name}</S.FleetName>
            <S.CustomTable
                dataSource={transformedVessels}
                columns={columns}
                rowKey="mmsi"
                rowClassName="customRow"
            />
            <S.CustomButton onClick={onBack}>Back to Fleets</S.CustomButton>
        </div>
    ) : (
        <div>
            <p>No fleet selected</p>
            <S.CustomButton onClick={onBack}>Back to Fleets</S.CustomButton>
        </div>
    );
};

export default FleetView;

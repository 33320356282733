import { BORDER_RADIUS } from '@app/common';
import { Button, Card } from '@components/index';
import styled, { css } from 'styled-components';

export const HorizontalStreamingCard = styled(Card)`
  width: 800px;
  height: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.18)
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
`;

export const VerticalStreamingCard = styled(Card)`
  margin: 0.5rem;
`;

export const HorizontalStreamingCardContainer = styled.div``;

export const CloseButton = styled(Button).attrs({ type: 'primary', size: 'small' })``;

export const FrameCanvas = styled.canvas`
  width: 100%;
  height: auto;

  border-radius: ${BORDER_RADIUS};
`;

export const VREContainer = styled.div`
  display: none;
  crossorigin: 'anonymous';
`;

import styled from 'styled-components';

// Outer box to contain the entire FleetUsagePercentage component
export const FleetUsageBox = styled.div`
    border: 2px solid #ddd; // Define the border for the outer box
    border-radius: 15px; // Rounded corners for smooth appearance
    padding: 20px; // Space between the content and the border
    background-color: #f9f9f9; // Light background color
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: subtle shadow for depth
    width: 100%; // Full width of the container
    max-width: 350px; // Adjust this as needed for your layout
    margin: 20px 0; // Removes horizontal centering
`;

export const FleetOverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Align items to the start (left) of the container
    p {
        margin-bottom: 10px; // Adds spacing between the text and the bar
    }
    button {
        margin-top: 10px; // Adds spacing between the bar and the button
    }
`;

export const ProgressBarWrapper = styled.div`
    max-width: 300px; // Adjust this value as needed
    width: 100%;
    height: 20px;
    background-color: transparent; // No background color for the wrapper
    border: 2px solid black; // Border to indicate the limits
    border-radius: 5px;
    position: relative; // To position the inner bar
    margin-bottom: 10px; // Adds spacing between the bar and any element below it
`;

export const ProgressBar = styled.div<{ width: string; color: string }>`
    height: 100%;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    width: ${(props) => props.width};
    position: absolute; // Position within the wrapper
    top: 0;
    left: 0;
`;


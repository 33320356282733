import { BASE_COLORS } from '@common/index';
import { ITheme } from '@common/interfaces';
import { shadeColor } from '@utils/index';

export const lightColorsTheme: ITheme = {
  primaryColor: '#01509A',
  primary1Color: '#f5f5f5',
  primaryGradient: 'linear-gradient(211.49deg, #006CCF 15.89%, #00509A 48.97%)',
  secondaryColor: '#0085FF',
  backgroundColor: BASE_COLORS.white,
  secondaryBackgroundColor: '#F8FBFF',
  secondaryBackgroundSelectedColor: shadeColor('#F8FBFF', -5),
  textMainColor: '#404040',
  textLightColor: '#9A9B9F',
  textSuperLightColor: '#BEC0C6',
  textSecondaryColor: BASE_COLORS.white,
  textDarkColor: '#404040',
  subtextColor: '#a9a9a9',
  spinnerBaseColor: '#339CFD',
  scrollColor: '#c5d3e0',
  borderColor: '#cce1f4',
  borderBaseColor: '#bec0c6',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
  boxShadowDarker: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
  boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  notificationSuccessColor: '#EFFFF4',
  notificationPrimaryColor: '#D7EBFF',
  notificationWarningColor: '#FFF4E7',
  notificationErrorColor: '#FFE2E2',
  headingColor: '#13264d',
  disableColor: 'rgba(0, 0, 0, 0.25)',
  disabledBgColor: '#c5d3e0',
  layoutBodyBgColor: '#f8fbff',
  layoutHeaderBgColor: 'transparent',
  layoutSiderBgColor: 'linear-gradient(261.31deg, #006ccf -29.57%, #00509a 121.11%)',
  inputPlaceholderColor: '#404040',
  itemHoverBgColor: '#f5f5f5',
  backgroundBaseColor: '#F5F5F5',
  avatarBgColor: '#ccc',
  alertTextColor: BASE_COLORS.white,
  iconColor: '#a9a9a9',
  iconHoverColor: 'rgba(0, 0, 0, 0.75)',
  errorColor: '#FF5252',
  warningColor: '#FFB765',
  successColor: '#57D682',
  chartBarBackgroundColor: 'rgba(180, 180, 180, 0.2)',
};

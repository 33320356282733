import { ApiResponseState, HeatmapEntry, RequestStatus, Track } from '@app/common';
import { createSlice } from '@reduxjs/toolkit';
import { fetchHeatmap, fetchMapTracks, fetchMapTracksHistory } from './thunks';

const mapTracksInitialState: ApiResponseState<{ [key: string]: Track }> & {
  selectedTrackId: string | undefined;
} = {
  status: RequestStatus.IDLE,
  data: {},
  error: undefined,
  selectedTrackId: undefined,
};
const trackByMMSIInitialState: ApiResponseState<Track> = {
  status: RequestStatus.IDLE,
  data: null,
  error: undefined,
};

const mapTrackHistoriesInitialState: ApiResponseState<{ [key: string]: Track[] }> = {
  status: RequestStatus.IDLE,
  data: {},
  error: undefined,
};

const heatmapTrakcEntriesInitialState: ApiResponseState<HeatmapEntry[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const initialState = {
  mapTracks: mapTracksInitialState,
  mapTrackHistories: mapTrackHistoriesInitialState,
  heatmapEntries: heatmapTrakcEntriesInitialState,
  trackByMMSI: trackByMMSIInitialState
};

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setSelectedMapTrackId: (state, action) => {
      state.mapTracks.selectedTrackId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

        .addCase(fetchMapTracks.pending, (state) => {
        state.mapTracks.status = RequestStatus.PENDING;
      })
      .addCase(fetchMapTracks.fulfilled, (state, action) => {
        state.mapTracks.status = RequestStatus.FULFILLED;
        state.mapTracks.data = action.payload;
      })
      .addCase(fetchMapTracks.rejected, (state, err) => {
        state.mapTracks.status = RequestStatus.REJECTED;
      })
      .addCase(fetchMapTracksHistory.pending, (state) => {
        state.mapTrackHistories.status = RequestStatus.PENDING;
      })
      .addCase(fetchMapTracksHistory.fulfilled, (state, action) => {
        state.mapTrackHistories.status = RequestStatus.FULFILLED;
        state.mapTrackHistories.data = action.payload;
      })
      .addCase(fetchMapTracksHistory.rejected, (state) => {
        state.mapTrackHistories.status = RequestStatus.REJECTED;
      })
      .addCase(fetchHeatmap.pending, (state) => {
        state.heatmapEntries.status = RequestStatus.PENDING;
      })
      .addCase(fetchHeatmap.fulfilled, (state, action) => {
        state.heatmapEntries.status = RequestStatus.FULFILLED;
        state.heatmapEntries.data = action.payload;
      })
      .addCase(fetchHeatmap.rejected, (state) => {
        state.heatmapEntries.status = RequestStatus.REJECTED;
      });
  },
});

export const { setSelectedMapTrackId } = trackSlice.actions;
export default trackSlice.reducer;

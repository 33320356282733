import React from 'react';
import { Modal, Input } from 'antd';

interface EditFleetModalProps {
    visible: boolean;
    editedFleetName: string;
    setEditedFleetName: (name: string) => void;
    editedFleetColor: string;
    setEditedFleetColor: (color: string) => void;
    handleUpdateFleet: () => void;
    onCancel: () => void;
}

const EditFleetModal: React.FC<EditFleetModalProps> = ({
                                                           visible,
                                                           editedFleetName,
                                                           setEditedFleetName,
                                                           editedFleetColor,
                                                           setEditedFleetColor,
                                                           handleUpdateFleet,
                                                           onCancel
                                                       }) => (
    <Modal
        title="Edit Fleet"
        visible={visible}
        onOk={handleUpdateFleet}
        onCancel={onCancel}
        okText="Save"
        cancelText="Cancel"
    >
        <Input
            placeholder="Fleet Name"
            value={editedFleetName}
            onChange={(e) => setEditedFleetName(e.target.value)}
        />
        <div style={{ marginTop: '10px' }}>
            <label htmlFor="colorPicker">Choose color:</label>
            <input
                id="colorPicker"
                type="color"
                value={editedFleetColor}
                onChange={(e) => setEditedFleetColor(e.target.value)}
                style={{ marginLeft: '10px' }}
            />
        </div>
    </Modal>
);

export default EditFleetModal;

import { IMapSettings, Track } from '@app/common';
import { useAppSelector } from '@app/hooks';
import L from 'leaflet';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const LeafletSelectZoomLayer: React.FC = () => {
  const map = useMap();
  const tracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
  const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);

  useEffect(() => {
    if (map) {
      const track: Track | undefined = tracks && selectedMapTrackId ? tracks[selectedMapTrackId] : undefined;

      const zoomLevel = map.getZoom();

      if (track && mapSettings.zoomOnSelect) {
        map.flyTo(new L.LatLng(track.location.lat, track.location.lon), zoomLevel > 8 ? zoomLevel : 8);
      }
    }
  }, [map, selectedMapTrackId, mapSettings.zoomOnSelect]);

  return null;
};

export default LeafletSelectZoomLayer;

import React from 'react';
import * as S from './CustomSider.style';

interface CustomSiderProps {
    children: React.ReactNode;
}

const CustomSider: React.FC<CustomSiderProps> = ({ children }) => {
    return (
        <S.CustomSider width={200} className="site-layout-background">
            {children}
        </S.CustomSider>
    );
};

export default CustomSider;

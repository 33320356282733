import { StreamingCamera, Track } from '@app/common';
import { useAppSelector, useResponsive } from '@app/hooks';
import React, { useState } from 'react';
import * as S from './HomePage.style';
import { MapManagementSection, MariticsMap, VesselCard } from './components';
import { StreamingCard } from './components/StreamingCard';



const HomePage: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();
  const [isSiderOpen, setIsSiderOpen] = useState<boolean>(true);

  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
  const mapTracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
  const selectedMapTrack: Track | undefined =
    mapTracks && selectedMapTrackId ? mapTracks[selectedMapTrackId] : undefined;
  const selectedStreamingCamera: StreamingCamera | null = useAppSelector(
    (state) => state.streams.selectedStreamingCamera,
  );

  return (

      <S.PageContainerRow>

      <S.MapContainerColumn xs={24} sm={24} md={24} lg={24} xl={isSiderOpen ? 20 : 24} xxl={isSiderOpen ? 20 : 24}>
        <S.MapContainerRow>
          <MariticsMap isSiderOpen={isSiderOpen} setIsSiderOpen={setIsSiderOpen} />

        </S.MapContainerRow>

        {selectedMapTrack && !isTablet && <VesselCard direction="horizontal" track={selectedMapTrack} />}
        {selectedStreamingCamera && !isDesktop && (
          <StreamingCard direction="vertical" camera={selectedStreamingCamera} />
        )}
      </S.MapContainerColumn>

      <S.MapContainerColumn xs={24} sm={24} md={24} lg={24} xl={4} xxl={4}>
        <MapManagementSection />
      </S.MapContainerColumn>

    </S.PageContainerRow>
  );
};

export default HomePage;

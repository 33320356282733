import React, {createContext, useContext, useEffect, useState} from "react";
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    User,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile
} from "firebase/auth";
import {auth} from "@app/firebase";
import {useNavigate} from "react-router-dom";
import {clearBearerToken, clearUser, fetchUser, persistBearerToken, persistUser} from "@app/services";
import {APPLICATION_PATHS} from "@app/routes";
import {useAppDispatch} from "@app/hooks";
import {setUserEmail} from "@store/slices/UserContent/slice";
type AuthContextProps = {
    currentUser: User | null;
    signout: () => Promise<void>;
    signin: (email: string, password: string, rememberMe?: boolean) => Promise<void>;
    signup: (fullName:string, email: string, password: string) => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    isAuthenticated: () => boolean;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    changeFullName: (newFullName: string)=> Promise<void>;
    getAccountCreationDate: () => string | null;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export function AuthProvider({children}: { children: React.ReactNode }) {
    const [currentUser, setCurrentUser] = useState<User | null>(fetchUser());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            if (user) {
                const userEmail = user.email || '';

                dispatch(setUserEmail(userEmail));
            } else {

                dispatch(setUserEmail(''));
            }
        });
    }, []);
    const getAccountCreationDate = (): string | null => {
        return currentUser?.metadata?.creationTime || null;
    };


/*
    // Bearer token cookie olarak ayarlanacağı zaman kullanılacak
    useEffect(() => {
        if (currentUser) {
            const refreshInterval = 60 * 60 * 2000; // 2 saat

            const refreshAuthToken = async () => {
                try {
                    const idToken = await currentUser.getIdToken(true);
                    await axios.post(`${USER_PATH}/refresh-token-cookie`, {idToken}, {withCredentials: true});
                } catch (error) {
                    console.error('Error refreshing auth token', error);
                }
            };

            refreshAuthToken();

            const intervalId = setInterval(refreshAuthToken, refreshInterval);

            return () => clearInterval(intervalId);
        }
    }, [currentUser]);
*/


    const signout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            clearBearerToken();
            clearUser();
        } catch (error) {
            throw new Error("Failed to sign out");
        }
    }

    const signup = async (fullName: string, email: string, password: string) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            if (userCredential.user) {

                await updateProfile(userCredential.user, { displayName: fullName });

                await sendEmailVerification(userCredential.user);
                navigate(APPLICATION_PATHS.SIGN_IN_PATH, { replace: true });
            }
        } catch (error: any) {
            if (error.code === 'auth/email-already-in-use') {
                throw new Error("This email is already in use. Please try logging in or use a different email.");
            }
            throw error;
        }
    };


    const signin = async (email: string, password: string, rememberMe?: boolean) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);

            const idToken = await userCredential.user.getIdToken();

            /*
            // Bearer token cookie olarak ayarlanacağı zaman kullanılacak
            await axios.post(`${USER_PATH}/set-token-cookie`, {idToken}, {withCredentials: true});
            */

            setCurrentUser(userCredential.user);
            if (userCredential!!) {
                if (rememberMe) {
                    persistUser(userCredential.user);
                    persistBearerToken(idToken);
                }
                navigate(APPLICATION_PATHS.APP_PATH);
            }
        } catch (error) {
            throw error;
        }
    }
    const isAuthenticated = () => {
        return !!currentUser;
    }
    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            throw error;
        }
    };
    const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
        if (currentUser && currentUser.email) {
            const credential = EmailAuthProvider.credential(
                currentUser.email,
                oldPassword
            );
            try {

                await reauthenticateWithCredential(currentUser, credential);


                await updatePassword(currentUser, newPassword);
            } catch (error: any) {

                if (error.code === 'auth/wrong-password') {
                    throw new Error("Entered old password doesn't match with actual password.");
                }
                throw new Error("Failed to change password. Please try again.");
            }
        } else {
            throw new Error("No authenticated user.");
        }
    };
    const changeFullName = async (newFullName: string): Promise<void> => {
        if (currentUser) {
            try {
                await updateProfile(currentUser, { displayName: newFullName });

            } catch (error: any) {
                throw new Error("Failed to update full name. Please try again.");
            }
        } else {
            throw new Error("No authenticated user.");
        }
    };


    return (
        <AuthContext.Provider value={{currentUser, signout, signin, signup, resetPassword, isAuthenticated, changePassword, changeFullName,  getAccountCreationDate}}>
            {children}
        </AuthContext.Provider>
    );
}



import { getMedia } from '@utils/index';
import { IDefaultPaddings, IMapFilters, IMapSettings, IModalSize } from './interfaces';
import { Countries } from './enums';
import moment from "moment";

export const BASE_COLORS = {
  white: '#ffffff',
  black: '#000000',
  green: '#008000',
  orange: '#ffb155',
  gray: '#808080',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff5252',
};

export const BORDER_RADIUS = '7px';

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.5rem',
    paddingHorizontal: '1rem',
    headerHeight: '42px',
  },
  desktop: {
    paddingVertical: '0.5rem',
    paddingHorizontal: '1rem',
    headerHeight: '42px',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  x5s: '0.40rem',
  x4s: '0.50rem',
  xxxs: '0.60rem',
  xxs: '0.75rem',
  xs: '0.875rem',
  sm: '0.9rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  x4l: '2rem',
  x5l: '3rem',
  x6l: '4rem',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const MODAL_SIZES: IModalSize = {
  small: '400px',
  medium: '600px',
  large: '800px',
};

export const DEFAULT_PADDINGS: IDefaultPaddings = {
  mobile: [30, 16],
  tablet: [40, 30],
  desktop: [50, 60],
};

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};

export const DEFAULT_FILTERS: IMapFilters = {
  timeRange: [moment().subtract(3, 'M').toISOString(), moment().toISOString()],
  trackLast: '15m',
  heatmapLast: '1y',
  mapBounds: {
    northWest: null,
    southEast: null,
  },
  vesselTypes: [],
  flags: [],
  destinations: [],
  speeds: []
};

export const DEFAULT_MAP_SETTINGS: IMapSettings = {
  isClustering: false,
  showHeatmap: false,
  showTracks: true,
  showCoordinateGrids: true,
  zoomOnSelect: false,
  refreshRate: 10 * 1000,
  isDataCurrent: true,

};

import React from 'react';
import { ButtonProps as AntButtonProps } from 'antd';
import { Severity } from '@common/index';
import * as S from './Button.styles';

export interface ButtonProps extends AntButtonProps {
  className?: string;
  noStyle?: boolean;
}

export const Button = React.forwardRef<HTMLElement, ButtonProps>(({ className, noStyle, children, ...props }, ref) => (
  <S.Button ref={ref} className={className} $noStyle={noStyle} {...props}>
    {children}
  </S.Button>
));

export default Button;

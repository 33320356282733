import React from 'react';
import { CollapsedHeader } from './CollapsedHeader';
import { ExpandedHeader } from './ExpandedHeader';
import { useResponsive } from '@app/hooks';

const Header: React.FC = () => {
  const { mobileOnly } = useResponsive();

  return mobileOnly ? <CollapsedHeader /> : <ExpandedHeader />;
};

export default Header;

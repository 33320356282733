import { LAYOUT, media } from '@common/index';
import { Layout } from 'antd';
import styled from 'styled-components';

const MainHeader = styled(Layout.Header)`
  line-height: 1.5;

  padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal};
  height: ${LAYOUT.mobile.headerHeight};

  box-shadow: 0 2.5px 2px -2px rgb(0, 0, 0, 0.1);

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
  }
`;

export default MainHeader;

import {FetchHistogramForm, FetchTracksForm, FilterType, HeatmapEntry, Track, TrackHistogram,} from '@common/index';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {generateFilter, generateFilterItem} from '@utils/index';
import Geohash from 'latlon-geohash';
import axiosInstance, {CancelToken, isCancel} from "@api/Axios/Axios";
import {Util} from "leaflet";
import isArray = Util.isArray;

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const TRACK_PATH = `${BASE_PATH}/track`;
const HEATMAP_PATH = `${BASE_PATH}/heatmap`;
const HISTOGRAM_PATH = `${BASE_PATH}/histogram`;

const getCategoricalPayload = (flags:any, vesselTypes:any, destinations:any) => {
  var categoricalPayload: any[] = [];

  if(flags && flags.length > 0) {
     categoricalPayload.push({'Flag' : flags})
  }

  if(vesselTypes && vesselTypes.length > 0) {
      categoricalPayload.push({'VesselType' : vesselTypes})
  }

  if(destinations && destinations.length > 0) {
      categoricalPayload.push({'Destination' : destinations})
  }

  return categoricalPayload;
}

const getNumericalPayload = (speedFilters:any) => {
  var numericalPayload: any[] = [];
  if(speedFilters && speedFilters.length > 0)  {
    numericalPayload.push({"Speed": speedFilters});
  }

  return numericalPayload;

}

export const fetchMapTracks = createAsyncThunk('map/fetchMapTracks', async ({filter, cancelToken}: {
  filter: FetchTracksForm, cancelToken?: CancelToken
}, {rejectWithValue}) => {

  const categoricalPayload = getCategoricalPayload(filter.flags, filter.vesselTypes, filter.destinations);
  const numericalPayload = getNumericalPayload(filter.speeds);

  const filterData = generateFilter([
          generateFilterItem(FilterType.TIME_RANGE, filter.timeRange),
          generateFilterItem(FilterType.GEOBOX, filter.geoBox),
    ...(categoricalPayload.length > 0) ? [generateFilterItem(FilterType.CATEGORICAL, categoricalPayload)] : [],
    ...(numericalPayload.length > 0) ? [generateFilterItem(FilterType.NUMERIC_COMPARISION, numericalPayload)] : [],
      ...(isArray(filter.mmsi) && filter.mmsi?.length!> 0 ? [generateFilterItem(FilterType.CATEGORICAL, [{"MMSI": filter.mmsi}])] : []),
      ...(filter.vesselTypes && filter.vesselTypes.length > 0
          ? [generateFilterItem(FilterType.CATEGORICAL, [{ 'VesselType': filter.vesselTypes }])]
          : []),
  ]);



  return await axiosInstance
      .post(`${TRACK_PATH}`, filterData, {cancelToken})
      .then((response) => response.data)
      .then((data) => {


        const result: { [key: string]: Track } = {};
        Object.keys(data.tracks).forEach((t) => {
          const track = data.tracks[t];
          result[t] = {
            timestamp: track['@timestamp'],
            updatetime: track.UpdateTime,
            mmsi: track.MMSI,
            imo: track.IMO,
            callsign: track.CallSign,
            name: track.Name,
            flag: track.Flag,
            speed: track.Speed,
            destination: track.destination,
            vesselType: track.VesselType,
            location: {
              lat: track.location.lat,
              lon: track.location.lon,
            },
            course: track.Course,
          };
        })
        return result;
      })
      .catch((error) => {
        if (isCancel(error)) {
          console.log('Request canceled', error.message);
          return rejectWithValue('Request canceled');
        } else {
          console.log('Request error', error);
          return rejectWithValue('Request error');
        }
      });
});

export const fetchMapTracksHistory = createAsyncThunk('map/fetchMapTracksHistory', async ({filter, cancelToken}: {

    filter: FetchTracksForm, cancelToken: CancelToken
}, {rejectWithValue}) => {
    const filterData = generateFilter([
        generateFilterItem(FilterType.TIME_RANGE, filter.timeRange),
        generateFilterItem(FilterType.GEOBOX, filter.geoBox),
        generateFilterItem(FilterType.HISTORY, true),
        ...(filter.mmsi ? [generateFilterItem(FilterType.CATEGORICAL, [{"MMSI": [filter.mmsi]}])] : []),
    ]);

    return await axiosInstance
        .post(`${TRACK_PATH}`, filterData, {cancelToken})
        .then((response) => response.data)
        .then((data) => {
            const result: { [key: string]: Track[] } = {};

            Object.keys(data.tracks).forEach((mmsi) => {
                result[mmsi] = data.tracks[mmsi].map((t: any) => {
                    const trackObj: Track = {
                        timestamp: t['@timestamp'],
                        updatetime: t.UpdateTime,
                        mmsi: t.MMSI,
                        imo: t.IMO,
                        callsign: t.CallSign,
                        name: t.Name,
                        flag: t.Flag,
                        speed: t.Speed,
                        destination: t.Destination,
                        vesselType: t.VesselType,
                        location: {
                            lat: t.location.lat,
                            lon: t.location.lon,
                        },
                        course: t.Course,
                    };

                    return trackObj;
                });
            })

            return result;
        })
        .catch((error) => {
            if (isCancel(error)) {
                console.log('Request canceled', error.message);
                return rejectWithValue('Request canceled');
            } else {
                console.log('Request error', error);
                return rejectWithValue('Request error');
            }
        });
});

export const fetchMapTrackHistogram = createAsyncThunk(
  'map/fetchMapTrackHistogram',
  async (filter: FetchHistogramForm) => {
    const categoricalPayload = getCategoricalPayload(filter.flags, filter.vesselTypes, filter.destinations);
    const numericalPayload = getNumericalPayload(filter.speeds);
    const filterData = generateFilter([
      generateFilterItem(FilterType.LAST, '1y'),
      generateFilterItem(FilterType.GEOBOX, filter.geoBox),
      ... (categoricalPayload.length > 0) ? [generateFilterItem(FilterType.CATEGORICAL, categoricalPayload)] : [],
       ... (numericalPayload.length > 0) ? [generateFilterItem(FilterType.NUMERIC_COMPARISION, numericalPayload)] : []
    ]);

    const response = await axiosInstance.post<TrackHistogram>(`${HISTOGRAM_PATH}`, filterData, {
      transformResponse: (data: any) => {
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        return parsedData;
      },
    });

    return response.data;
  },
);

export const fetchHeatmap = createAsyncThunk('map/fetchHeatmap', async ({filter, cancelToken}: {
    filter: FetchTracksForm, cancelToken: CancelToken
}, {rejectWithValue}) => {
    const categoricalPayload = getCategoricalPayload(filter.flags, filter.vesselTypes, filter.destinations);
    const numericalPayload = getNumericalPayload(filter.speeds);
    const filterData = generateFilter([
        generateFilterItem(FilterType.TIME_RANGE, filter.timeRange),
        generateFilterItem(FilterType.GEOBOX, filter.geoBox),
        ...(categoricalPayload.length > 0) ? [generateFilterItem(FilterType.CATEGORICAL, categoricalPayload)] : [],
        ...(numericalPayload.length > 0) ? [generateFilterItem(FilterType.NUMERIC_COMPARISION, numericalPayload)] : []
    ]);

    return await axiosInstance
        .post<HeatmapEntry[]>(`${HEATMAP_PATH}`, filterData, {cancelToken})
        .then((response) => response.data)
        .then((data) => {
            const result: HeatmapEntry[] = data.map((bucket: any) => {
                const {lat, lon} = Geohash.decode(bucket.key);
                const entry: HeatmapEntry = {
                    count: bucket.doc_count,
                    lat,
                    lon,
                };
                return entry;
            });
            return result;
        }).catch((error) => {
            if (isCancel(error)) {
                console.log('Request canceled', error.message);
                return rejectWithValue('Request canceled');
            } else {
                console.log('Request error', error);
                return rejectWithValue('Request error');
            }
        });
});

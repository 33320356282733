const APPLICATION_PATHS = {
  ROOT_PATH: '/',

  AUTH_PATH: '/auth',
  SIGN_IN_PATH: '/auth/sign-in',
  SIGN_UP_PATH: '/auth/sign-up',
  FORGOT_PASSWORD_PATH: '/auth/forgot-password',

  APP_PATH: '/app',

  ACCOUNT_PATH: '/app/account',
};

export default APPLICATION_PATHS;

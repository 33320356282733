import { LAYOUT, media } from '@common/constants';
import { Layout } from 'antd';
import styled from 'styled-components';

const MainContent = styled(Layout.Content)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default MainContent;

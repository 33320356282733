import React, { useState } from 'react';
import { Form } from 'antd';
import { useAuth } from '@app/context/AuthContext';
import * as S from './ChangePassword.style';

const ChangePasswordForm: React.FC = () => {
    const [form] = Form.useForm();
    const { changePassword } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const handleSubmit = async (values: { oldPassword: string; newPassword: string; confirmPassword: string }) => {
        const { oldPassword, newPassword, confirmPassword } = values;

        setError(null);
        setSuccess(false);

        if (newPassword !== confirmPassword) {
            setError("New passwords do not match.");
            return;
        }

        try {
            await changePassword(oldPassword, newPassword);
            setSuccess(true);
            form.resetFields();
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <S.Container>
            <S.Title>Change Password</S.Title>
            <Form form={form} onFinish={handleSubmit}>
                <S.Label htmlFor="oldPassword">Old Password</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="oldPassword"
                        rules={[{ required: true, message: 'Please input your old password!' }]}
                    >
                        <S.PasswordInput
                            id="oldPassword"
                            placeholder="Enter Old Password"
                        />
                    </Form.Item>
                </S.InputContainer>

                <S.Label htmlFor="newPassword">New Password</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="newPassword"
                        rules={[{ required: true, message: 'Please input your new password!' }]}
                    >
                        <S.PasswordInput
                            id="newPassword"
                            placeholder="Enter New Password"
                        />
                    </Form.Item>
                </S.InputContainer>

                <S.Label htmlFor="confirmPassword">Confirm New Password</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="confirmPassword"
                        rules={[{ required: true, message: 'Please confirm your new password!' }]}
                    >
                        <S.PasswordInput
                            id="confirmPassword"
                            placeholder="Confirm New Password"
                        />
                    </Form.Item>
                </S.InputContainer>

                {error && <S.Error>{error}</S.Error>}
                {success && <S.Success>Password changed successfully!</S.Success>}

                <Form.Item>
                    <S.Button type="submit">Change Password</S.Button>
                </Form.Item>
            </Form>
        </S.Container>
    );
};

export default ChangePasswordForm;

import { InputNumber as AntInputNumber } from 'antd';
import styled, { css } from 'styled-components';

interface IInputNumberProps {
  $block?: boolean;
}

export const InputNumber = styled(AntInputNumber)<IInputNumberProps>`
  ${(props) =>
    props.$block &&
    css`
      width: 100%;
    `}
`;

import styled from 'styled-components';
import { Input } from 'antd';

export const Container = styled.div`
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
`;

export const Title = styled.h2`
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
    font-size: 1.2rem;
`;

export const InputContainer = styled.div`
    position: relative;
    width: 250px; 
`;

export const PasswordInput = styled(Input.Password)`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;

    
    .ant-input-suffix {
        margin-right: 8px; 
    }
`;

export const Label = styled.label`
    margin: 8px 0 4px;
    font-weight: bold;
    font-size: 0.9rem;
`;

export const Error = styled.p`
    color: red;
    margin-top: -5px;
    margin-bottom: 8px;
    font-size: 0.9rem;
`;

export const Success = styled.p`
    color: green;
    margin-top: -5px;
    margin-bottom: 8px;
    font-size: 0.9rem;
`;

export const Button = styled.button`
    background-color: #1890ff;
    border: 1px;
    color: white;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    width: 250px;
    &:hover {
        background-color: #40a9ff;
    }
`;

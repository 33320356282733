import React from 'react';
import { List, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface FleetListProps {
    fleets: any[];
    handleFetchFleetVessels: (fleetName: string) => void;
    handleEditFleet: (index: number) => void;
    handleDeleteFleet: (index: number) => void;
}

const FleetList: React.FC<FleetListProps> = ({
                                                 fleets,
                                                 handleFetchFleetVessels,
                                                 handleEditFleet,
                                                 handleDeleteFleet
                                             }) => (
    <List
        header={<div>My Fleets</div>}
        bordered
        dataSource={fleets}
        renderItem={(item, index) => (
            <List.Item
                actions={[
                    <Tooltip title="Edit Fleet" key={index}>
                        <Button
                            type="text"
                            icon={<EditOutlined style={{ color: 'blue' }} />}
                            onClick={() => handleEditFleet(index)}
                        />
                    </Tooltip>,
                    <Tooltip title="Delete Fleet" key={index}>
                        <Button
                            type="text"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            onClick={() => handleDeleteFleet(index)}
                        />
                    </Tooltip>
                ]}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        backgroundColor: item.color,
                        marginRight: 8
                    }}></div>
                    <Button type="link" onClick={() => handleFetchFleetVessels(item.name)}>
                        {item.name}
                    </Button>
                </div>
            </List.Item>
        )}
        style={{ marginTop: 16 }}
    />
);

export default FleetList;

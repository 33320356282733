const interpolateColor = (value: number, min: number, max: number) => {
    const ratio = (value - min) / (max - min);
    const r = Math.round(255);
    const g = Math.round(165 * (1 - ratio));
    const b = Math.round(0);
    return `rgb(${r}, ${g}, ${b})`;
};

import React from 'react';
import { ProgressBarWrapper, ProgressBar, FleetOverviewContainer, FleetUsageBox } from './FleetUsagePercentage.style';

interface FleetUsagePercentageProps {
    fleetUsage: number;
}

const FleetUsagePercentage: React.FC<FleetUsagePercentageProps> = ({ fleetUsage }) => {
    const percentage = (fleetUsage / 5) * 100;
    const color = interpolateColor(fleetUsage, 0, 5);

    return (
        <FleetUsageBox>
        <FleetOverviewContainer>
            <p>Total Fleet Usage: {fleetUsage}/5</p>
            <ProgressBarWrapper>
                <ProgressBar width={`${percentage}%`} color={color} />
            </ProgressBarWrapper>
        </FleetOverviewContainer>
        </FleetUsageBox>
    );
};

export default FleetUsagePercentage;
import React from "react";
import { Button, Form, Input, message, Typography } from "antd";
import * as S from './SignUpPage.style';
import { Link } from "react-router-dom";
import { useAuth } from "@app/context/AuthContext";
import { APPLICATION_PATHS } from "@app/routes";
import { addUserByEmail } from "@store/slices/UserContent/thunks";
import { useAppDispatch } from "@app/hooks";

export default function SignUpPage() {
    const [form] = Form.useForm();
    const { signup } = useAuth();
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: { fullName: string, email: string, password: string }) => {
        try {
            await signup(values.fullName, values.email, values.password)
                .then(() => {
                    dispatch(addUserByEmail({ email: values.email }));
                });
            message.success("Account created successfully!");
            form.resetFields();
        } catch (error: any) {
            message.error(error.message);
        }
    };


    const validateFullName = (_: any, value: string) => {
        const words = value.trim().split(/\s+/);
        if (value && words.length < 2 || words.some(word => word.length < 2)) {
            return Promise.reject("Full name must contain at least two words, each with at least two letters.");
        }
        if (value && words.length > 3) {
            return Promise.reject("Full name cannot contain more than three words.");
        }
        return Promise.resolve();
    };

    return (
        <S.PageContainerRow justify="center">
            <S.PageContainerColumn xs={16} sm={12} lg={8}>
                <Typography.Title level={2} style={{ textAlign: 'center' }}>
                    Sign Up
                </Typography.Title>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{ fullName: '', email: '', password: '' }}
                >
                    <Form.Item
                        name="fullName"
                        rules={[
                            { required: true, message: 'Please input your full name!' },
                            { validator: validateFullName }
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input placeholder="Full Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    >
                        <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!', min: 6 }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Sign Up
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Typography.Text>
                            Already have an account?{' '}
                            <Link to={APPLICATION_PATHS.SIGN_IN_PATH}>Sign In</Link>
                        </Typography.Text>
                    </Form.Item>
                </Form>
            </S.PageContainerColumn>
        </S.PageContainerRow>
    );
}

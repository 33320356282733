import React, { useState } from 'react';
import * as S from './MapLegendCard.style';
import { VesselType } from '@app/common';
import { getVesselTypeColor } from '@app/utils';
import { Badge, Space } from '@app/components';
import { Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const MapLegendCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const VESSEL_TYPE_COLORS: { color: string; title: string }[] = Object.keys(VesselType).map((vt) => {
    const vesselType = VesselType[vt as keyof typeof VesselType];

    return {
      color: getVesselTypeColor(vesselType),
      title: vesselType,
    };
  });

  return (
    <S.MapLegendCardContainer>
      <S.MapLegendCard padding={[5, 10]}>
        <Row justify="end" align="middle">
          {isOpen ? (
            <S.CustomDownOutlined onClick={() => setIsOpen((prev) => !prev)} />
          ) : (
            <S.CustomUpOutlined onClick={() => setIsOpen((prev) => !prev)} />
          )}
        </Row>
        {VESSEL_TYPE_COLORS.slice(0, !isOpen ? 1 : VESSEL_TYPE_COLORS.length - 1).map((vtbadge) => (
          <S.VesselBadge color={vtbadge.color} text={vtbadge.title} />
        ))}
      </S.MapLegendCard>
    </S.MapLegendCardContainer>
  );
};

export default MapLegendCard;

import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import AutoGraticule from 'leaflet-auto-graticule';
import L from 'leaflet';

const LeaefletCoordinateGridLayer: React.FC = () => {
  const map: L.Map = useMap();

  useEffect(() => {
    let coordinateGridLayer: L.Layer;

    if (map) {
      coordinateGridLayer = new AutoGraticule().addTo(map);
    }

    return () => {
      coordinateGridLayer && map.removeLayer(coordinateGridLayer);
    };
  }, [map]);

  return null;
};

export default LeaefletCoordinateGridLayer;

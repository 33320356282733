import { BORDER_RADIUS } from '@app/common';
import { Card } from '@components/index';
import EChartsReact from 'echarts-for-react';
import styled from 'styled-components';
import {Button, DatePicker, Slider} from "antd";
import {useSelector} from "react-redux";
import {RootState} from "@app/store";

export const GlobalFilterCardContainer = styled.div`
  z-index: 400;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0.5rem;
`;

export const GlobalFilterCard = styled(Card)`
  width: 100%;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: ${BORDER_RADIUS};
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const GlobalTimeFilterCard = styled(Card)`
  height: fit-content;
`;

export const GlobalTimeFilterChart = styled(EChartsReact)`
  height: 50px !important;
`;

export const GlobalTimeFilterChartContainer = styled.div`
  height: fit-content;
`;
export const GlobalTimeFilterRangePicker= styled(DatePicker.RangePicker)`
    z-index: 10000 !important;
    .ant-picker-input > input {
        font-size: 14px;
        font-weight: 500;
    }
    `;
export const GlobalTimeFilterSlider= styled(Slider)`
    margin-bottom: 5px;
`
export const CurrentDataButton= styled(Button)`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    opacity: ${({theme}) => {
        const isDataCurrent = useSelector((state: RootState) => state.mapSettings.isDataCurrent);
        return isDataCurrent ? 1 : 0.5;
    }};
    background-color: ${({theme}) => {
        const isDataCurrent = useSelector((state: RootState) => state.mapSettings.isDataCurrent);
        return isDataCurrent ? '#b1d5a9' : '#e1d5d5';
    }};
    color: ${({theme}) => {
        const isDataCurrent = useSelector((state: RootState) => state.mapSettings.isDataCurrent);
        return isDataCurrent ? 'green' : '#2f2e2e';
    }};
`
export const ResetButton= styled(Button)`
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
`

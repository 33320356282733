import {Button, Form, Input, message, Typography} from "antd";
import * as S from './ForgotPasswordPage.style';
import {useNavigate} from "react-router-dom";
import {useAuth} from "@app/context/AuthContext";


export default function ForgotPasswordPage() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {resetPassword} = useAuth();

    const handleSubmit = async (values: { email: string }) => {
        try {
            await resetPassword(values.email);
            message.success("Password reset email sent! Check your inbox.");
            form.resetFields();
        } catch (error: any) {
        }
    };

    return (
        <S.PageContainerRow justify={'center'}>
            <S.PageContainerColumn xs={16} sm={12} lg={8}>
                <Typography.Title level={2} style={{textAlign: 'center'}}>
                    Reset Password
                </Typography.Title>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{email: ''}}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: 'Please input your email!', type: 'email'}]}
                    >
                        <Input type="email" placeholder={"Email"}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            Reset Password
                        </Button>
                    </Form.Item>
                    <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                        <Button onClick={() => {
                            navigate(-1)
                        }}>Go back</Button>
                    </Form.Item>
                </Form>
            </S.PageContainerColumn>
        </S.PageContainerRow>
    );
}

import { media } from '@app/common';
import { Collapse as BaseCollapse } from '@components/index';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  @media only screen and ${media.xl} {
    overflow-y: auto;
  }
`;

export const Collapse = styled(BaseCollapse)`
  &.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0 !important;
  }

  border-radius: 0;
`;

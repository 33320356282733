import UnknownFlagSVG from '@assets/images/unknownFlag.svg';
import { BORDER_RADIUS, FONT_SIZE, media } from '@common/index';
import { Card as BaseCard, Button, P2 } from '@components/index';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';

export const VerticalCardContainer = styled.div``;

export const VerticalCard = styled(BaseCard)`
  width: 280px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const HorizontalCard = styled(BaseCard)`
  height: fit-content;
  margin: 0.5rem;
`;

export const VerticalCardCloseButtonContainer = styled.div`
  position: absolute;
  right: 3%;
  top: 3%;
`;

export const VerticalCardVesselImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CloseButton = styled(Button).attrs({ type: 'primary', size: 'small' })``;

export const VerticalCardVesselImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${BORDER_RADIUS};
  object-fit: cover;
  max-height: 150px;
`;

export const HorizontalCardVesselImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const HorizontalCardVesselImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${BORDER_RADIUS};
  object-fit: cover;
  max-height: 150px;

  @media only screen and ${media.md} {
    max-height: 200px;
  }

  @media only screen and ${media.lg} {
    max-height: 250px;
  }
`;

export const UnknownFlag = styled.img.attrs({ src: UnknownFlagSVG })`
  width: 1.25rem;
  height: 1.25rem;
`;

export const InformationText = styled(P2)`
  &.ant-typography {
    color: var(--text-light-color);
  }
`;

export const ItalicText = styled(P2)`
  &.ant-typography {
    color: var(--text-light-color);
    font-style: italic;
  }
`;

export const CountryFlag = styled(ReactCountryFlag)`
  font-size: ${FONT_SIZE.xl} !important;
`;

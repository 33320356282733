import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
`;

export const InfoRow = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

export const Label = styled.p`
    margin: 0;
    font-weight: bold;
    width: 150px; /* Başlıklar için genişlik belirleme */
`;

export const Value = styled.p`
    margin: 0;
    flex: 1; /* Değerlerin kalan alanı kaplamasını sağlar */
    padding-left: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

export const CustomButton = styled.button`
  background-color: #1890ff;
  border: none;
  color: white;
  padding: 9px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #40a9ff;
  }
`;

import {DEFAULT_FILTERS, DEFAULT_MAP_SETTINGS, IMapFilters, IMapSettings, Language, Theme} from '@common/index';
import {User} from "firebase/auth";

//Language Service Methods
export const persistLanguage = (langugage: Language): void => {
    localStorage.setItem('lng', langugage);
};

export const fetchLanguage = (): Language | undefined => {
    const language = localStorage.getItem('lng');
    return language ? (language as Language) : undefined;
};

// Theme Service Methods
export const persistTheme = (theme: Theme) => {
    localStorage.setItem('theme', theme);
};

export const fetchTheme = (): Theme | undefined => {
    const theme = localStorage.getItem('theme');
    return theme ? (theme as Theme) : undefined;
};

// Bearer Token Service Methods
export const readBearerToken = (): string => {
    return localStorage.getItem('bearerToken') || '';
};

export const persistBearerToken = (token: string) => {
    localStorage.setItem('bearerToken', token);
};

export const clearBearerToken = () => {
    localStorage.removeItem('bearerToken');
};

// Filters Service Methods

export const persistFilters = (filters: IMapFilters) => {
    localStorage.setItem('filters.timeRange', JSON.stringify(filters.timeRange));
    localStorage.setItem('filters.trackLast', filters.trackLast);
    localStorage.setItem('filters.heatmapLast', filters.heatmapLast);
    localStorage.setItem('filters.mapBounds.northWest', JSON.stringify(filters.mapBounds.northWest));
    localStorage.setItem('filters.mapBounds.southEast', JSON.stringify(filters.mapBounds.southEast));
    localStorage.setItem('filters.flags', JSON.stringify(filters.flags));
    localStorage.setItem('filters.vesselTypes', JSON.stringify(filters.vesselTypes));
    localStorage.setItem('filters.destinations', JSON.stringify(filters.destinations));
    localStorage.setItem('filters.speeds', JSON.stringify(filters.speeds));
};

export const fetchFilters = (): IMapFilters => {
    const timeRange = localStorage.getItem('filters.timeRange');
    const trackLast = localStorage.getItem('filters.trackLast');
    const heatmapLast = localStorage.getItem('filters.heatmapLast');
    const northWest = localStorage.getItem('filters.mapBounds.northWest');
    const southEast = localStorage.getItem('filters.mapBounds.southEast');
    const flags = localStorage.getItem('filters.flags');
    const vesselTypes = localStorage.getItem('filters.vesselTypes');
    const destinations= localStorage.getItem('filters.destinations');
    const speeds= localStorage.getItem('filters.speeds');

    const filters: IMapFilters = {
        timeRange: timeRange ? JSON.parse(timeRange) : DEFAULT_FILTERS.timeRange,
        trackLast: trackLast ? trackLast : DEFAULT_FILTERS.trackLast,
        heatmapLast: heatmapLast ? heatmapLast : DEFAULT_FILTERS.heatmapLast,
        mapBounds: {
            northWest: northWest ? JSON.parse(northWest) : DEFAULT_FILTERS.mapBounds.northWest,
            southEast: southEast ? JSON.parse(southEast) : DEFAULT_FILTERS.mapBounds.southEast,
        },
        vesselTypes: vesselTypes ? JSON.parse(vesselTypes) : DEFAULT_FILTERS.vesselTypes,
        flags: flags ? JSON.parse(flags) : DEFAULT_FILTERS.flags,
        destinations: destinations? JSON.parse(destinations) : DEFAULT_FILTERS.destinations,
        speeds: speeds? JSON.parse(speeds): DEFAULT_FILTERS.speeds
    };

    return filters;
};

export const persistMapBounds = (mapBounds: {
    northWest: [number, number] | null;
    southEast: [number, number] | null;
}) => {
    localStorage.setItem('filters.mapBounds.northWest', JSON.stringify(mapBounds.northWest));
    localStorage.setItem('filters.mapBounds.southEast', JSON.stringify(mapBounds.southEast));
}
export const fetchMapBounds = (): {
    northWest: [number, number] | null;
    southEast: [number, number] | null;
} => {
    const northWest = localStorage.getItem('filters.mapBounds.northWest');
    const southEast = localStorage.getItem('filters.mapBounds.southEast');
    return {
        northWest: northWest ? JSON.parse(northWest) : null,
        southEast: southEast ? JSON.parse(southEast) : null,
    };
}

// Map Settings Service Methods

export const persistMapSettings = (settings: IMapSettings) => {
    localStorage.setItem('mapSettings.isClustering', settings.isClustering.toString());
    localStorage.setItem('mapSettings.showHeatmap', settings.showHeatmap.toString());
    localStorage.setItem('mapSettings.showTracks', settings.showTracks.toString());
    localStorage.setItem('mapSettings.showCoordinateGrids', settings.showCoordinateGrids.toString());
    localStorage.setItem('mapSettings.zoomOnSelect', settings.zoomOnSelect.toString());
    localStorage.setItem('mapSettings.refreshRate', settings.refreshRate.toString());
};
export const persistMapSettingsDataCurrent = (isDataCurrent: boolean) => {
    localStorage.setItem('mapSettings.isDataCurrent', isDataCurrent.toString());
}

export const fetchMapSettings = (): IMapSettings => {
    const isClustering = localStorage.getItem('mapSettings.isClustering')
        ? localStorage.getItem('mapSettings.isClustering') === 'true'
        : DEFAULT_MAP_SETTINGS.isClustering;

    const showTracks = localStorage.getItem('mapSettings.showTracks')
        ? localStorage.getItem('mapSettings.showTracks') === 'true'
        : DEFAULT_MAP_SETTINGS.showTracks;

    const showHeatmap = localStorage.getItem('mapSettings.showHeatmap')
        ? localStorage.getItem('mapSettings.showHeatmap') === 'true'
        : DEFAULT_MAP_SETTINGS.showHeatmap;

    const showCoordinateGrids = localStorage.getItem('mapSettings.showCoordinateGrids')
        ? localStorage.getItem('mapSettings.showCoordinateGrids') === 'true'
        : DEFAULT_MAP_SETTINGS.showCoordinateGrids;

    const zoomOnSelect = localStorage.getItem('mapSettings.zoomOnSelect')
        ? localStorage.getItem('mapSettings.zoomOnSelect') === 'true'
        : DEFAULT_MAP_SETTINGS.zoomOnSelect;
    const refreshRate = localStorage.getItem('mapSettings.refreshRate')
        ? Number(localStorage.getItem('mapSettings.refreshRate'))
        : DEFAULT_MAP_SETTINGS.refreshRate;
    const isDataCurrent = localStorage.getItem('mapSettings.isDataCurrent')
        ? localStorage.getItem('mapSettings.isDataCurrent') === 'true'
        : DEFAULT_MAP_SETTINGS.isDataCurrent;

    const mapSettings: IMapSettings = {
        isClustering,
        showHeatmap,
        showTracks,
        showCoordinateGrids,
        zoomOnSelect,
        refreshRate,
        isDataCurrent
    };

    return mapSettings;
};

// Stream Settings Service Methods

export const persistClassificationConfidence = (confidence: number) => {
    localStorage.setItem('streams.classificationConfidence', confidence.toString());
};

export const persistDetectionConfidence = (confidence: number) => {
    localStorage.setItem('streams.detectionConfidence', confidence.toString());
};

export const fetchStreamSettings = () => {
    const detectionConfidence = Number(localStorage.getItem('streams.detectionConfidence')) || 0.5;
    const classificationConfidence = Number(localStorage.getItem('streams.classificationConfidence')) || 0.5;

    return {
        detectionConfidence,
        classificationConfidence,
    };
};

// Leaflet Map Service Methods
export const persistLatestZoomLevel = (zoomLevel: number) => {
    localStorage.setItem('map.zoomLevel', zoomLevel.toString());
};

export const fetchLatestZoomlevel = (): number | null => {
    return Number(localStorage.getItem('map.zoomLevel'));
};

export const persistLatestMapCenter = (lat: number, lon: number) => {
    localStorage.setItem('map.center.lat', lat.toString());
    localStorage.setItem('map.center.lon', lon.toString());
};

export const fetchLatestMapCenter = (): { lat: number | undefined; lon: number | undefined } => {
    const lat = localStorage.getItem('map.center.lat');
    const lon = localStorage.getItem('map.center.lon');

    return {
        lat: Number(lat),
        lon: Number(lon),
    };
};

export const fetchLatestMapBounds = (): {
    northWest: [number, number] | null;
    southEast: [number, number] | null;
} => {
    const northWest = localStorage.getItem('map.northWest');
    const southEast = localStorage.getItem('map.southEast');

    const parsedNorthWest = northWest ? JSON.parse(northWest) : null;
    const parsedSouthWest = southEast ? JSON.parse(southEast) : null;

    return {
        northWest: parsedNorthWest,
        southEast: parsedSouthWest,
    };
};

export const persistLatestMapBounds = (
    northWest: [number, number] | null,
    southEast: [number, number] | null,
): void => {
    localStorage.setItem('map.northWest', JSON.stringify(northWest));
    localStorage.setItem('map.southEast', JSON.stringify(southEast));
};

export const persistUser = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
}

export const clearUser = () => {
    localStorage.removeItem('user');
}

export const fetchUser = (): User | null => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

import { FONT_SIZE } from '@app/common';
import { Button } from '@components/index';
import EChartsReact from 'echarts-for-react';
import styled from 'styled-components';

export const EChart = styled(EChartsReact)`
  margin-top: 0.125rem;
`;

export const LinkButton = styled(Button)`
  span {
    font-size: ${FONT_SIZE.xxs};
    text-decoration: none;
  }
`;

export const EChartContainer = styled.div`
  width: 100%;
  height: 100px;
`;

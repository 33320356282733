import styled, { css } from 'styled-components';
import { Space as BaseSpace, SpaceProps } from 'antd';

export const Space = styled(BaseSpace)<SpaceProps>`
  width: 100%;
  ${(props) =>
    props.direction === 'vertical'
      ? css`
          width: 100%;
        `
      : css`
          width: fit-content;
        `}
`;

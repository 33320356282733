import { BASE_COLORS, IThemesContainer, Theme } from '@common/index';
import { css } from 'styled-components';
import { darkColorsTheme } from './dark/DarkTheme';
import { lightColorsTheme } from './light/LightTheme';

export const themeObject: IThemesContainer = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

const getThemeVariables = (theme: Theme) => css`
  color-scheme: ${theme};

  --primary-color: ${themeObject[theme].primaryColor};
  --primary1-color: ${themeObject[theme].primary1Color};
  --primary-gradient: ${themeObject[theme].primaryGradient};
  --secondary-color: ${themeObject[theme].secondaryColor};

  --background-color: ${themeObject[theme].backgroundColor};
  --secondary-background-color: ${themeObject[theme].secondaryBackgroundColor};
  --secondary-background-selected-color: ${themeObject[theme].secondaryBackgroundSelectedColor};

  --text-main-color: ${themeObject[theme].textMainColor};
  --text-light-color: ${themeObject[theme].textLightColor};
  --text-dark-color: ${themeObject[theme].textDarkColor};
  --text-super-light-color: ${themeObject[theme].textSuperLightColor};
  --text-secondary-color: ${themeObject[theme].textSecondaryColor};
  --subtext-color: ${themeObject[theme].subtextColor};

  --spinner-base-color: ${themeObject[theme].spinnerBaseColor};
  --scroll-color: ${themeObject[theme].scrollColor};
  --border-color: ${themeObject[theme].borderColor};
  --border-base-color: ${themeObject[theme].borderBaseColor};

  --shadow: ${themeObject[theme].shadow};
  --box-shadow: ${themeObject[theme].boxShadow};
  --box-shadow-darker: ${themeObject[theme].boxShadowDarker};
  --box-shadow-hover: ${themeObject[theme].boxShadowHover};

  --error-color: ${themeObject[theme].errorColor};
  --success-color: ${themeObject[theme].successColor};
  --warning-color: ${themeObject[theme].warningColor};

  --notification-success-color: ${themeObject[theme].notificationSuccessColor};
  --notification-primary-color: ${themeObject[theme].notificationPrimaryColor};
  --notification-warning-color: ${themeObject[theme].notificationWarningColor};
  --notification-error-color: ${themeObject[theme].notificationErrorColor};

  --heading-color: ${themeObject[theme].headingColor};

  --disable-color: ${themeObject[theme].disableColor};
  --disabled-bg-color: ${themeObject[theme].disabledBgColor};

  --layout-body-bg-color: ${themeObject[theme].layoutBodyBgColor};
  --layout-header-bg-color: ${themeObject[theme].layoutHeaderBgColor};
  --layout-sider-bg-color: ${themeObject[theme].layoutSiderBgColor};

  --input-placeholder-color: ${themeObject[theme].inputPlaceholderColor};
  --item-hover-bg-color: ${themeObject[theme].itemHoverBgColor};
  --background-base-color: ${themeObject[theme].backgroundBaseColor};

  --avatar-bg-color: ${themeObject[theme].avatarBgColor};
  --alert-text-color: ${themeObject[theme].alertTextColor};

  --icon-color: ${themeObject[theme].iconColor};
  --icon-hover-color: ${themeObject[theme].iconHoverColor};

  --chart-bar-background-color: ${themeObject[theme].chartBarBackgroundColor};
`;

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`;

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;

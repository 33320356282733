import React, { useState, useEffect } from 'react';
import * as S from './FleetOverview.style';
import { Dispatch, SetStateAction } from 'react';
import { Modal, Input, Select } from 'antd';

import FleetUsagePercentage from '../FleetUsagePercentage/FleetUsagePercentage';
import { List, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { Popover } from 'antd';
import {Fleet} from "@app/common";

interface FleetOverviewProps {
    fleets: Fleet[];
    fleetUsage: number;
    setIsModalVisible: Dispatch<SetStateAction<boolean>>;
    onAddFleet: () => void;
    onViewFleet: (fleet: Fleet) => void;
    onUpdateFleet: (editedFleet: Fleet, updatedFleet: Fleet) => void;
    onEditFleet: (fleet: Fleet) => void;
    onDeleteFleet: (index: number, userEmail: string) => void;
    onDeleteVessel: (fleetName: string, vesselMmsi: string, userEmail: string) => void;
    userEmail: string;
}

const FleetOverview: React.FC<FleetOverviewProps> = ({
                                                         fleets,
                                                         fleetUsage,
                                                         setIsModalVisible,
                                                         onAddFleet,
                                                         onViewFleet,
                                                         onUpdateFleet,
                                                         onEditFleet,
                                                         onDeleteFleet,
                                                         onDeleteVessel,
                                                         userEmail
                                                     }) => {
    const [localFleets, setLocalFleets] = useState<Fleet[]>(fleets);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [fleetToDelete, setFleetToDelete] = useState<number | null>(null);
    const [isLimitReachedModalVisible, setIsLimitReachedModalVisible] = useState(false);
    const [isDeleteVesselModalVisible, setIsDeleteVesselModalVisible] = useState(false);
    const [fleetToDeleteVesselFrom, setFleetToDeleteVesselFrom] = useState<Fleet | null>(null);
    const [vesselToDelete, setVesselToDelete] = useState<string | null>(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editedFleet, setEditedFleet] = useState<Fleet | null>(null);
    const [editedFleetName, setEditedFleetName] = useState('');
    const [editedFleetColor, setEditedFleetColor] = useState('');
    //<S.CustomButton onClick={() => onEditFleet(fleet)}>Add Vessel</S.CustomButton>
    const handleViewFleet = (fleet) => {
        onViewFleet(fleet);
        setLocalFleets(localFleets);
    }
    useEffect(() => {
        setLocalFleets(fleets);
    }, [fleets]);
    const handleUpdateFleet = () => {
        if (editedFleet) {

            const updatedFleetData = {
                ...editedFleet,
                name: editedFleetName,
                color: editedFleetColor,
            };

            onUpdateFleet(editedFleet, updatedFleetData);

            // Update the local state with the updated fleet data
            setLocalFleets(localFleets.map(fleet =>
                fleet.name === editedFleet.name ? updatedFleetData : fleet
            ));


            setIsEditModalVisible(false);
            setEditedFleet(null);
            setEditedFleetName('');
            setEditedFleetColor('');
        } else {

        }
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
        setEditedFleet(null);
    };

    const showEditModal = (fleet: Fleet) => {

        setEditedFleet(fleet);
        setEditedFleetName(fleet.name);
        setEditedFleetColor(fleet.color);
        setIsEditModalVisible(true);
    };

    const showDeleteModal = (index: number) => {
        setFleetToDelete(index);
        setIsDeleteModalVisible(true);
    };

    const handleDelete = () => {
        if (fleetToDelete !== null) {
            onDeleteFleet(fleetToDelete, userEmail);

            // Remove the fleet from local state
            setLocalFleets(localFleets.filter((_, index) => index !== fleetToDelete));

            setIsDeleteModalVisible(false);
            setFleetToDelete(null);
        }
    };

    const handleCancel = () => {
        setIsDeleteModalVisible(false);
        setFleetToDelete(null);
    };

    const handleAddFleetClick = () => {
        if (fleetUsage >= 5) {
            setIsLimitReachedModalVisible(true);
        } else {
            setIsModalVisible(true);
            onAddFleet();
        }
    };

    const handleLimitReachedModalOk = () => {
        setIsLimitReachedModalVisible(false);
    };





    const handleDeleteVessel = () => {
        if (fleetToDeleteVesselFrom && vesselToDelete) {
            onDeleteVessel(fleetToDeleteVesselFrom.name, vesselToDelete, userEmail);
            setIsDeleteVesselModalVisible(false);
            setFleetToDeleteVesselFrom(null);
            setVesselToDelete(null);
        }
    };

    const handleDeleteVesselCancel = () => {
        setIsDeleteVesselModalVisible(false);
        setFleetToDeleteVesselFrom(null);
        setVesselToDelete(null);
    };

    return (
        <div>
            <S.MyFleets>My Fleets</S.MyFleets>
            <FleetUsagePercentage fleetUsage={fleetUsage} />
            <Popover content="Add New Fleet" placement="top">
            <S.AddNewFleetButton onClick={handleAddFleetClick}><Icon icon="material-symbols:add" width={20} height={18}/></S.AddNewFleetButton>
            </Popover>
            <div>

                <List
                    header={<S.fleetOverview>Fleet Overview</S.fleetOverview>}
                    bordered
                    dataSource={localFleets}
                    renderItem={(fleet, index) => (
                        <List.Item
                            actions={[
                                <Tooltip title="View Fleet" key={`view-${fleet.name}`}>
                                    <Button
                                        type="text"
                                        icon={<EyeOutlined />}
                                        onClick={() => handleViewFleet(fleet)}
                                    />
                                </Tooltip>,
                                <Tooltip title="Update Fleet" key={`update-${fleet.name}`}>
                                    <Button
                                        type="text"
                                        icon={<EditOutlined style={{ color: 'blue' }} />}
                                        onClick={() => showEditModal(fleet)}
                                    />
                                </Tooltip>,


                                <Tooltip title="Delete Fleet" key={`delete-${fleet.name}`}>
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                                        onClick={() => showDeleteModal(index)}
                                    />
                                </Tooltip>
                            ]}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: fleet.color,
                                        borderRadius: '50%',
                                        marginRight: '10px'
                                    }}
                                />
                                <span>{fleet.name}</span>
                            </div>
                        </List.Item>
                    )}
                    style={{ marginTop: 16 }}
                />
            </div>


            <Modal
                title="Confirm Deletion"
                visible={isDeleteModalVisible}
                onOk={handleDelete}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to delete this fleet?</p>
            </Modal>

            <Modal
                title="Fleet Limit Reached"
                visible={isLimitReachedModalVisible}
                onOk={handleLimitReachedModalOk}
                okText="OK"
                closable={false}
                footer={[
                    <Button key="ok" type="primary" onClick={handleLimitReachedModalOk}>
                        OK
                    </Button>
                ]}
            >
                <p>You have reached the maximum number of fleets (5). Please delete an existing fleet before adding a new one.</p>
            </Modal>


            <Modal
                title="Delete Vessel"
                visible={isDeleteVesselModalVisible}
                onOk={handleDeleteVessel}
                onCancel={handleDeleteVesselCancel}
                okText="Delete"
                cancelText="Cancel"
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select a vessel to delete"
                    onChange={(value) => setVesselToDelete(value)}
                >
                    {fleetToDeleteVesselFrom?.vessels.map((vessel) => (
                        <Select.Option key={vessel.mmsi} value={vessel.mmsi}>
                            {vessel.mmsi}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>

            <Modal
                title="Edit Fleet"
                visible={isEditModalVisible}
                onOk={() => {

                    handleUpdateFleet();
                }}
                onCancel={handleEditModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Input
                    placeholder="Fleet Name"
                    value={editedFleetName}
                    onChange={(e) => setEditedFleetName(e.target.value)}
                />
                <div style={{ marginTop: '10px' }}>
                    <label htmlFor="colorPicker">Choose color:</label>
                    <input
                        id="colorPicker"
                        type="color"
                        value={editedFleetColor}
                        onChange={(e) => setEditedFleetColor(e.target.value)}
                        style={{ marginLeft: '10px' }}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default FleetOverview;

import React from 'react';
import { Modal, Input } from 'antd';

interface EditROIModalProps {
    visible: boolean;
    editedROIName: string;
    setEditedROIName: (name: string) => void;
    handleUpdateROI: () => void;
    onCancel: () => void;
}

const EditROIModal: React.FC<EditROIModalProps> = ({
                                                       visible,
                                                       editedROIName,
                                                       setEditedROIName,
                                                       handleUpdateROI,
                                                       onCancel
                                                   }) => (
    <Modal
        title="Edit ROI"
        visible={visible}
        onOk={handleUpdateROI}
        onCancel={onCancel}
        okText="Save"
        cancelText="Cancel"
    >
        <Input
            placeholder="ROI Name"
            value={editedROIName}
            onChange={(e) => setEditedROIName(e.target.value)}
            style={{ marginBottom: 16 }}
        />

    </Modal>
);

export default EditROIModal;

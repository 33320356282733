import { AppLogo, AppLogoTitle } from '@app/components/common';
import { Row } from 'antd';
import React from 'react';
import {AccountDropdown} from "@components/layout/Header/AccountDropdown";

const CollapsedHeader: React.FC = () => {
  return (
    <Row justify="space-between" align="middle">
      <AppLogo width="25px" height="25px" />
      <AppLogoTitle title="Maritics" />
      <AccountDropdown />
    </Row>
  );
};

export default CollapsedHeader;

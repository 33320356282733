// roiSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RegionOfInterest } from '@app/common';
const initialState: { currentRegionOfInterest: RegionOfInterest | null } = {

    currentRegionOfInterest: null
};

const roiSlice = createSlice({
    name: 'roiSlice',
    initialState,
    reducers: {

        setCurrentRegionOfInterest: (state, action: PayloadAction<RegionOfInterest | null>) => {
            state.currentRegionOfInterest = action.payload;

        },
        clearCurrentRegionOfInterest: (state) => {
            state.currentRegionOfInterest = null;

        }
    }
});

export const { setCurrentRegionOfInterest, clearCurrentRegionOfInterest } = roiSlice.actions;
export default roiSlice.reducer;

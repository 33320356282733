import styled from 'styled-components';
import { Table } from 'antd';
import ReactCountryFlag from "react-country-flag";
import {FONT_SIZE} from "@app/common";
import UnknownFlagSVG from "@assets/images/unknownFlag.svg";

export const CustomTable = styled(Table)`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th, td {
        padding: 8px;
        border: none; /* Remove the borders */
    }

    th {
        background-color: #f2f2f2;
        text-align: left;
    }

    .customRow:nth-child(even) {
        background-color: #f9f9f9;
    }

    .flagImage {
        width: 20px;
        height: 15px;
    }

    .vesselPhoto {
        width: 50px;
        height: 50px;
    }

    .vesselName {
        font-weight: bold;
    }
`;

export const CustomButton = styled.button`
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #40a9ff;
    }
`;

export const TableContainer = styled.div`
    position: relative;
`;

export const DeleteButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
export const FleetName = styled.h1`
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 20px;
  /* Add any additional styling if needed */
`;
export const CountryFlag = styled(ReactCountryFlag)`
  font-size: ${FONT_SIZE.xxxl} !important;
`;


export const UnknownFlag = styled.img.attrs({ src: UnknownFlagSVG })`
  width: 1.25rem;
  height: 1.25rem;
`;
import { Button, DropdownMenu } from '@app/components';
import { Row } from 'antd';
import { MapContainer as BaseMapContainer } from 'react-leaflet';
import { styled } from 'styled-components';

export const MapOuterContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const MapContainer = styled(BaseMapContainer)`
  height: 100%;
  position: relative;

  .leaflet-control-attribution {
    display: none;
  }
`;

export const LayoutToggler = styled(Button)`
  position: absolute;
  z-index: 400;
  top: 1rem;
  right: 1rem;
`;

export const GlobalTimeFilterRow = styled(Row)`
  position: absolute;
  bottom: 0;
  z-index: 400;
  margin: 0.5rem;
  width: 98%;
`;

export const SettingsOverlayToggler = styled(Button)`
  position: absolute;
  z-index: 400;
  top: 1rem;
  right: 1rem;
`;

export const SettingsDropdown = styled(DropdownMenu)`
  position: absolute;
  z-index: 400;
  top: 10rem;
  right: 0.5rem;
`;

export const VesselCardContainer = styled.div`
    position: absolute;
    z-index: 400;
    top: 40%;
    left: 0.5rem;
    transform: translateY(-50%);
`;


export const StreamingCardContainer = styled.div`
  z-index: 400;
  display: flex;
  position: absolute;
  padding: 0.5rem;
  bottom: 8rem;
`;

export const StreamingCardCloseButton = styled(Button).attrs({ type: 'primary', size: 'small' })`
  position: absolute;
  top: 10px;
  right: 10px;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VesselState {
    vesselMmsiList: string[];
    isOn: boolean; // Add isOn state
}

const initialState: VesselState = {
    vesselMmsiList: [],
    isOn: false, // Initialize with default value
};

const vesselSlice = createSlice({
    name: 'vessel',
    initialState,
    reducers: {
        setVesselMmsiList(state, action: PayloadAction<string[]>) {
            state.vesselMmsiList = action.payload;
        },
        addVesselMmsi(state, action: PayloadAction<string>) {
            state.vesselMmsiList.push(action.payload);
        },
        clearVesselMmsiList(state) {
            state.vesselMmsiList = [];
        },
        setIsOn(state, action: PayloadAction<boolean>) {
            state.isOn = action.payload;
        },
    },
});

export const { setVesselMmsiList, addVesselMmsi, clearVesselMmsiList, setIsOn } = vesselSlice.actions;

export default vesselSlice.reducer;

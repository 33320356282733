import styled from "styled-components";
import {Col, Row} from "antd";

export const PageContainerRow = styled(Row)`
    height: 100%;
`;

export const PageContainerColumn = styled(Col)`
    height: 100%;
    align-content: center;
`;

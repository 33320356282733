import { styled } from 'styled-components';
import { H2, H3 } from '../Typography';
import { FONT_WEIGHT, media } from '@common/index';
import { Typography } from 'antd';

export const Title = styled(Typography.Text)`
  &.ant-typography {
    font-family: Neuropolitical;
    font-weight: ${FONT_WEIGHT.semibold};
    margin-bottom: 0;

    color: var(--primary-color);
  }
`;

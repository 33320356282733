import { media } from '@app/common';
import { Button, Col, Row } from 'antd';
import styled, { css } from 'styled-components';

export const PageContainerRow = styled(Row)`
    height: fit-content;

    @media only screen and ${media.xl} {
        height: 100%;
    }
`;

export const MapContainerColumn = styled(Col)`
    height: 100%;
`;

export const MapContainerRow = styled(Row)`
    height: 50vh;
    margin-bottom: 0.5rem;

    @media only screen and ${media.md} {
        height: 75vh;
    }

    @media only screen and ${media.xl} {
        height: 100%;
    }
`;

export const MapDetailsCol = styled(Col)`
    height: 100%;
    overflow-x: scroll;
`;

export const StreamingCardCloseButton = styled(Button).attrs({ type: 'primary', size: 'small' })`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const ButtonContainerColumn = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CustomButton = styled(Button)`
  margin: 10px 0;
`;

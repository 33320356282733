import { AppLogo, AppLogoTitle } from '@components/index';
import { Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { APPLICATION_PATHS } from '@app/routes';
import {AccountDropdown} from "@components/layout/Header/AccountDropdown";

const ExpandedHeader: React.FC = () => {
  return (
    <Row justify="space-between" align="middle">
      <Link to={APPLICATION_PATHS.APP_PATH} >
        <Row align="middle">
          <AppLogo width="25px" height="25px" margin="0 0.625rem 0 0" />
          <AppLogoTitle title="Maritics" />
        </Row>
      </Link>

      <AccountDropdown />
    </Row>
  );
};

export default ExpandedHeader;

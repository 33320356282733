import { Header, MainContent, MainHeader } from '@components/layout';
import React, { ReactNode } from 'react';
import * as S from './MainLayout.style';

interface MainLayoutProps {
    children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <S.LayoutMaster>
            <MainHeader>
                <Header />
            </MainHeader>
            <MainContent>
                {children}
            </MainContent>
        </S.LayoutMaster>
    );
};

export default MainLayout;

import { Theme } from '@common/index';
import { useAppSelector } from '@hooks/index';
import { themeObject } from '@styles/themes/ThemeVariables';
import { ConfigProvider } from 'antd';
import { useEffect, useRef } from 'react';

const useThemeWatcher = (): void => {
  const root = useRef(document.querySelector(':root'));
  const theme: Theme = useAppSelector((state) => state.theme.data);

  useEffect(() => {
    const html = root.current;
    if (html) {
      html.setAttribute('data-no-transition', '');
      html.setAttribute('data-theme', theme);

      requestAnimationFrame(() => {
        html.removeAttribute('data-no-transition');
      });
    }

    ConfigProvider.config({
      theme: {
        primaryColor: themeObject[theme].primaryColor,
        infoColor: themeObject[theme].primaryColor,
        successColor: themeObject[theme].successColor,
        errorColor: themeObject[theme].errorColor,
        warningColor: themeObject[theme].warningColor,
      },
    });
  }, [theme]);
};

export default useThemeWatcher;

import styled from 'styled-components';

export const FleetOverviewContainer = styled.div`
    margin-bottom: 20px;
`;

export const ProgressBarContainer = styled.div`
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
`;

export const ProgressBar = styled.div`
    height: 100%;
    background-color: #1890ff;
`;

export const AddNewFleetButton = styled.button`
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #40a9ff;
    }
`;

export const CustomButton = styled.button`
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #40a9ff;
    }
`;

export const FleetRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FleetName = styled.div`
  flex: 1;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px; /* Butonlar arasındaki boşluk */
`;
export const MyFleets = styled.h1`
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 20px;
  /* Add any additional styling if needed */
`;
export const fleetOverview = styled.h1`
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 0px;
  /* Add any additional styling if needed */
`;
import React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import { Container, Label } from './FleetModeSwitch.style';

interface FleetModeSwitchProps extends SwitchProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const FleetModeSwitch: React.FC<FleetModeSwitchProps> = ({ label, checked, onChange, ...props }) => {
    return (
        <Container>
            <Label>{label}</Label>
            <Switch
                checked={checked}
                onChange={onChange}
                {...props}
            />
        </Container>
    );
};

export default FleetModeSwitch;

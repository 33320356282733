import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance} from "@app/api";
import {CancelToken} from "@api/index";
import {Fleet, RegionOfInterest} from '@app/common';
import { setRegionOfInterests, addRegionOfInterest, setCurrentRegionOfInterest } from "./slice";
import axios from "axios";


const USER_PATH = process.env.REACT_APP_USER_PATH;


export const addUserByEmail = createAsyncThunk(
    'user/addUserByEmail',
    async ({ email }: {email:string} , { rejectWithValue }) => {
        try {
            await axios.put(`${USER_PATH}/add/user?email=${email}`);
            return { email };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to add user');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const fetchAllROIs = createAsyncThunk(
    'user/fetchAllROIs',
    async ({ email, cancelToken }: { email:string, cancelToken: CancelToken }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.get(`${USER_PATH}/get/allROIs?email=${email}`, {
                cancelToken,
            });
            const data: RegionOfInterest[] = response.data;
            dispatch(setRegionOfInterests(data));
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to fetch ROIs");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const addROI = createAsyncThunk(
    'user/addROI',
    async ({ email ,ROIData }: { email:string ,ROIData: RegionOfInterest }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.post(`${USER_PATH}/ROI/add`, {
                email: email,
                ROIData
            });
            const newROI: RegionOfInterest = response.data;
            dispatch(addRegionOfInterest(newROI));

            return newROI;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to add ROI");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const getROI = createAsyncThunk(
    'user/getROI',
    async ({email, roiName }: {email:string,  roiName: string }, { rejectWithValue, dispatch }) => {
        try {
            if(email) {
                const response = await axiosInstance.get(`${USER_PATH}/get/ROI?email=${email}&roiName=${roiName}`);
                const roiData: RegionOfInterest = response.data;
                dispatch(setCurrentRegionOfInterest(roiData));
                return roiData;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to fetch ROI");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const deleteROI = createAsyncThunk(
    'user/deleteROI',
    async ({email,  roiName }: { email:string, roiName: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.delete(`${USER_PATH}/delete/ROI?email=${email}&roiName=${roiName}`);
            return true;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to fetch ROI");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const editROI = createAsyncThunk(
    'user/editROI',
    async (
        {
            email,
            currentROIName,
            newROIName
        }: {
            email:string,
            currentROIName: string;
            newROIName: string;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await axiosInstance.put(`${USER_PATH}/update/ROI`, {
                email: email,
                currentROIName,
                newROIName
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to update ROI");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);


export const addFleet = createAsyncThunk(
    'fleets/addFleet',
    async ({email,  fleetData }: { email:string, fleetData: Fleet }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.post(`${USER_PATH}/fleets`, {
                email: email,
                fleetData
            });
            return response;
        } catch (error) {

            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || "Failed to add fleet");
            } else {
                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const getFleetByName = createAsyncThunk(
    'fleet/getFleetByName',
    async ({email,  fleetName }: { email:string, fleetName: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${USER_PATH}/fleets/name/${fleetName}?email=${email}`);
            const fleetData = response.data;
            return fleetData;
        } catch (error) {
            if (axios.isAxiosError(error)) {

                return rejectWithValue(error.response?.data || "Failed to fetch fleet data");
            } else {

                return rejectWithValue("An unexpected error occurred");
            }
        }
    }
);
export const fetchFleetVesselsByName = createAsyncThunk(
    'fleet/fetchFleetVesselsByName',
    async ({email,  fleetName }: { email:string, fleetName: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${USER_PATH}/fleets/${fleetName}/vessels?email=${email}`);
            if (response) {
                return response.data;
            } else {
                return rejectWithValue('Failed to fetch fleet vessels');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to fetch fleet vessels');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const fetchFleetsByEmail = createAsyncThunk(
    'fleet/fetchFleetsByEmail',
    async ({email}:{email:string}, { rejectWithValue }) => {
        try {

            const response = await axios.get(`${USER_PATH}/fleets/user/user?email=${email}`);

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to fetch fleets');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const deleteFleet = createAsyncThunk(
    'fleet/deleteFleet',
    async ({email,  fleetName }: {email:string,  fleetName: string }, { rejectWithValue }) => {
        try {
            await axios.delete(`${USER_PATH}/fleets/${fleetName}?email=${email}`);
            return fleetName;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to delete fleet');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const deleteVesselFromFleet = createAsyncThunk(
    'fleet/deleteVesselsFromFleet',
    async ({email,  fleetName, vesselMmsi }: {email:string,  fleetName: string; vesselMmsi: string }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${USER_PATH}/fleets/remove/vessels?email=${email}&name=${fleetName}`, {
                data: {
                    vesselMmsi
                }
            });
            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue('Failed to delete vessels from fleet');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to delete vessels from fleet');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const updateFleet = createAsyncThunk(
    'fleet/updateFleet',
    async (payload: {email:string, fleetName: string; fleetData: { color: string; name: string; } }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${USER_PATH}/update`, {
                email: payload.email,
                fleetName: payload.fleetName,
                fleetData: payload.fleetData,
            });

            if (response.status === 200) {

                return response.data;
            } else {
                return rejectWithValue('Failed to update fleet');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to update fleet');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
export const addVesselToFleet = createAsyncThunk(
    'fleets/addVesselToFleet',
    async ({email,  fleetName, vesselMmsi }: {email:string,  fleetName: string; vesselMmsi: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${USER_PATH}/fleets/vessels?email=${email}&name=${fleetName}`, {

                mmsi: vesselMmsi,
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to update fleet');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);



import React, { Suspense } from 'react';

type ReturnType<T> = (props: T) => JSX.Element;

const withLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
  return (props: T) => (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Component {...props} />
    </Suspense>
  );
};

export default withLoading;


import {  Panel } from '@components/index';
import React, { useRef } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AnalyticPanel } from '../AnalyticPanel';
import * as S from './MapManagementSection.style';




  const MapManagementSection: React.FC = () => {
    const { t } = useTranslation();
    const canvasRef = useRef(null);



    return (
        <S.Container>




            {/* Analytics Panel */}
            <S.Collapse
                bordered={false}
                defaultActiveKey={['analyticsPanel']}
                expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
            >
                <Panel header={t('pages.home.analytics')} key="analyticsPanel">
                    <AnalyticPanel/>
                </Panel>
            </S.Collapse>

        </S.Container>
    );
  };

export default MapManagementSection;

import { MoreOutlined } from '@ant-design/icons';
import { IDropdownMenuItemOption } from '@common/index';
import { Dropdown } from '@components/common';
import { Button } from 'antd';
import React from 'react';
import ItemOptionsPopover from './ItemOptionsPopover';
import * as S from './DropdownMenu.styles';

interface IDropdownMenuProps {
  icon?: React.ReactNode;
  buttonContent?: React.ReactNode;
  customPopover?: React.ReactElement;
  options?: IDropdownMenuItemOption[];
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({ icon, buttonContent, customPopover, options }) => {
  const renderDropdownContent = () => {
    return buttonContent ? (
      buttonContent
    ) : (
      <Button
        type="text"
        size="small"
        icon={icon ? icon : <MoreOutlined />}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      />
    );
  };

  return (
    <S.Dropdown
      overlay={customPopover ? customPopover : <ItemOptionsPopover options={options || []} />}
      placement="bottomRight"
      trigger={['click']}
      getPopupContainer={() => document.body}
    >
      {renderDropdownContent()}
    </S.Dropdown>
  );
};

export default DropdownMenu;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Fleet, RegionOfInterest} from '@app/common';
//import {fetchUserTracks} from "@store/slices/UserContent/thunks";

// TODO types and reducers are examples, replace them with your own.
const initialState: { fullName:string ,email: string,userId: string, fleets: Fleet[], regionOfInterests: RegionOfInterest[], currentRegionOfInterest: RegionOfInterest | null, roiCount: number, roiDrawerVisible: boolean, roiPopoverVisible: boolean, fleetToFetch: string[] } = {
    fullName: "",
    email: "",
    userId: "",
    fleets: [],
    regionOfInterests: [],
    currentRegionOfInterest: null,
    roiCount: 0,
    roiDrawerVisible: false,
    roiPopoverVisible: false,
    fleetToFetch: [],
}

export const userContentSlice = createSlice({
    name: 'userContent',
    initialState,
    reducers: {
        setFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;

        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setFleets: (state, action: PayloadAction<Fleet[]>) => {
            state.fleets = action.payload;
        },
        setRegionOfInterests: (state, action: PayloadAction<RegionOfInterest[]>) => {
            state.regionOfInterests = action.payload;
        },
        addRegionOfInterest: (state, action: PayloadAction<RegionOfInterest>) => {
            state.regionOfInterests.push(action.payload);
        },
        setCurrentRegionOfInterest: (state, action: PayloadAction<RegionOfInterest | null>) => {
            state.currentRegionOfInterest = action.payload;

        },

        clearCurrentRegionOfInterest: (state) => {
            state.currentRegionOfInterest = null;

        },
        setRoiCount: (state, action: PayloadAction<number>) => {
            state.roiCount = action.payload;
        },
        incrementROI: (state) => {
            state.roiCount += 1;
        },
        decrementROI: (state) => {
            state.roiCount -= 1;
        },
        setRoiDrawerVisible: (state, action: PayloadAction<boolean>) => {
            state.roiDrawerVisible = action.payload;
        },
        setRoiPopoverVisible: (state, action: PayloadAction<boolean>) => {
            state.roiPopoverVisible = action.payload;
        },
        setFleetToFetch (state, action: PayloadAction<string[]>) {

            state.fleetToFetch = action.payload;
        },
        clearFleetToFetch(state) {
            state.fleetToFetch = [];
        },
    },
});

export const {
    setFullName,
    setUserEmail,
    setUserId,
    setFleets,
    setRegionOfInterests,
    addRegionOfInterest,
    setCurrentRegionOfInterest,
    clearCurrentRegionOfInterest,
    setRoiCount,
    incrementROI,
    decrementROI,
    setRoiDrawerVisible,
    setRoiPopoverVisible,
    setFleetToFetch,
    clearFleetToFetch} = userContentSlice.actions;
export default userContentSlice.reducer;

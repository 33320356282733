import React from 'react';
import { Menu } from 'antd';
import * as S from './CustomMenu.style';

interface CustomMenuProps {
    selectedKey: string;
    onSelect: (key: string) => void;
}

const CustomMenu: React.FC<CustomMenuProps> = ({ selectedKey, onSelect }) => {
    return (
        <S.CustomMenu mode="inline" selectedKeys={[selectedKey]} onSelect={({ key }) => onSelect(key)}>
            <Menu.Item key="overview">Account Overview</Menu.Item>
            <Menu.Item key="my_fleets">My Fleets</Menu.Item>

            <Menu.Item key="roi">Region of Interests</Menu.Item>
        </S.CustomMenu>
    );
};

export default CustomMenu;

import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const CustomContent = styled(Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;

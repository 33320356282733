import React from 'react';
import * as S from './FleetEditModal.style';

interface FleetEditModalProps {
    isVisible: boolean;
    onOk: () => void;
    onCancel: () => void;
    newVesselMmsi: number | undefined;
    onVesselMmsiChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FleetEditModal: React.FC<FleetEditModalProps> = ({ isVisible, onOk, onCancel, newVesselMmsi, onVesselMmsiChange }) => {
    return (
        <S.CustomModal
            title="Add Vessel to Fleet"
            visible={isVisible}
            onOk={onOk}
            onCancel={onCancel}
        >
            <S.CustomInput
                placeholder="Enter vessel MMSI number"
                value={newVesselMmsi ? newVesselMmsi.toString() : ''}
                onChange={onVesselMmsiChange}
            />
        </S.CustomModal>
    );
};

export default FleetEditModal;

import { useAppDispatch, useResponsive } from '@app/hooks';
import { DropdownMenu } from '@components/index';
import { APPLICATION_PATHS } from '@routes/index';
import { Avatar, message, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from "@app/context/AuthContext";
import { useNavigate } from "react-router-dom";
import {lightColorsTheme} from "@styles/themes/light/LightTheme";

const AccountOverlay: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { mobileOnly } = useResponsive();
  const navigate = useNavigate();
  const { currentUser, signout } = useAuth();


  const getInitials = (fullName: string) => {
    return fullName
        .split(' ')
        .map(name => name[0].toUpperCase())
        .join('');
  };


  const handleLogout = async () => {
    try {
      await signout();
      navigate(APPLICATION_PATHS.APP_PATH);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleLogin = () => {
    navigate(APPLICATION_PATHS.SIGN_IN_PATH);
  };

  const handleAccountNavigate = () => {
    navigate(APPLICATION_PATHS.ACCOUNT_PATH);
  };

  const MENU_ITEMS = currentUser ? [
    {
      key: 'account',
      title: t('header.account.title'),
      onClick: handleAccountNavigate,
    },
    {
      key: 'signout',
      title: t('header.account.sign_out'),
      onClick: handleLogout,
    },
  ] : [{
    key: 'signin',
    title: t('header.account.sign_in'),
    onClick: handleLogin,
  }];


  const triggerButton: React.ReactElement = (
      <Space>
        <Avatar
            size={28}
            style={{ backgroundColor: lightColorsTheme.primaryColor }}
        >
          {currentUser?.displayName ? getInitials(currentUser.displayName) : '?'}
        </Avatar>
      </Space>
  );

  return <DropdownMenu buttonContent={triggerButton} options={MENU_ITEMS} />;
};

export default AccountOverlay;

import { FONT_WEIGHT } from '@common/index';
import { Card, Text } from '@components/index';
import { Row } from 'antd';
import { styled } from 'styled-components';

export const PopoverCard = styled(Card)`
  box-shadow: var(--box-shadow-darker);
  z-index: 999;
  min-width: 300px;
`;

export const SettingsTitle = styled(Text)`
  &.ant-typography {
    font-weight: ${FONT_WEIGHT.semibold};
  }
`;

export const SettingsRow = styled(Row)`
  justify-content: space-between;
  min-width: 300px;
`;

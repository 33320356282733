import { css, styled } from 'styled-components';

interface IAppLogoImageProps {
  margin?: string;
  height?: string;
  width?: string;
}

export const AppLogoContainer = styled.div<IAppLogoImageProps>`
  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : css`
          margin-right: 0.625rem;
        `}
`;

export const AppLogoImage = styled.img<IAppLogoImageProps>`
  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css`
          height: 25px;
        `}

  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 25px;
        `}
`;

import { SpaceProps } from 'antd';
import React from 'react';
import * as S from './Space.style';

const Space: React.FC<SpaceProps> = (props) => {
  const { direction } = props;

  return (
    <S.Space {...props} direction={direction}>
      {props.children}
    </S.Space>
  );
};

export default Space;

import { CollapseProps } from 'antd';
import * as S from './Collapse.styles';
import { Collapse as AntdCollapse } from 'antd';

export const { Panel } = AntdCollapse;

const Collapse: React.FC<CollapseProps> = (props) => {
  return <S.Collapse {...props} />;
};

export default Collapse;

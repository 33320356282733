import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { Button, Space, Popover, message } from 'antd';
import { useTranslation } from 'react-i18next';
import FleetModal from './FleetModal';
import ROIDrawer from './ROIDrawer';
import { useAppSelector } from '@app/hooks';
interface MainMapPopoverProps {
    zoomLevel: number;
}
import { addFleet, fetchFleetsByEmail } from '@store/slices/UserContent/thunks';
import {setRoiDrawerVisible} from "@store/slices/UserContent/slice";
import { useAppDispatch } from '@app/hooks';
import {useAuth} from "@app/context/AuthContext";

const MainMapPopover: React.FC<MainMapPopoverProps> = ({
                                                           zoomLevel,
                                                       }) => {
    const { t } = useTranslation();
    const [isFleetModalVisible, setIsFleetModalVisible] = useState(false);
    const [fleets, setFleets] = useState<any[]>([]);
    const [fleetUsage, setFleetUsage] = useState<number>(0);
    const BASE_PATH = process.env.REACT_APP_USER_PATH;
    const [isFleetMode, setIsFleetMode] = useState<boolean>(false);
    const [allVesselMMSIs, setAllVesselMMSIs] = useState<string[]>([]);
    const {currentUser} = useAuth();
    const currentROI = useAppSelector((state) => state.roi.currentRegionOfInterest);
    const isRoiDrawerVisible = useAppSelector((state) => state.roi.roiDrawerVisible );
    const userEmail = currentUser?.email ? currentUser.email : "";
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchFleetsByEmail({email: userEmail}))
            .unwrap()

            .then(data => {
                setFleets(data);
                setFleetUsage(data.length);
            })
            .catch(err => {
                console.log(err);
            });
    }, [BASE_PATH, userEmail]);

    const handleAddFleet = (name: string, color: string) => {


        if (fleetUsage >= 5) {
            return;
        }

        const newFleet = {
            color,
            name,
            vessels: []
        };

        const requestBody = {
            email: userEmail,
            fleetData: newFleet
        };

        dispatch(addFleet(requestBody))
            .unwrap()
            .then(() => {
                const updatedFleets = [...fleets, newFleet];
                setFleets(updatedFleets);
                setFleetUsage(updatedFleets.length);
            })
            .catch((error) => {
                console.error("Error adding fleet:", error);
            });
    };

    const showFleetModal = () => {
        if (!userEmail) {
            message.warning(t('You have to log in first'));
            return;
        }
        setIsFleetModalVisible(true);
    };

    const handleCloseFleetModal = () => {
        setIsFleetModalVisible(false);
    };
    const handleROIClick = () => {
        if (!userEmail) {
            message.warning(t('You have to log in first'));
            return;
        }
        dispatch(setRoiDrawerVisible(true));
    };

    useEffect(() => {
        if(currentROI)

            dispatch(setRoiDrawerVisible(false));
    }, [currentROI]);
    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <div
                style={{
                    position: 'fixed',
                    top: '50px',
                    left: isFleetModalVisible  || isRoiDrawerVisible ? '420px' : '20px',
                    zIndex: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    transition: 'left 0.3s ease',
                }}
            >
                <Space direction="vertical" style={{marginBottom: '30px'}}>
                    <Popover
                        content={<div style={{ fontSize: '15px', width: '180px', height: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t('My Fleets Management')}</div>}
                        title={null}
                        trigger="hover"
                        mouseLeaveDelay={0.1}
                        placement="right"
                    >
                        <Button type="primary" block onClick={showFleetModal}>
                            <Icon icon="mingcute:ship-fill" height={25}/>
                        </Button>
                    </Popover>
                    <Popover
                        content={<div style={{ fontSize: '15px', width: '170px', height: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t('My ROI management')}</div>} // Daha küçük yazı boyutu
                        title={null}
                        trigger="hover"
                        mouseLeaveDelay={0.1}
                        placement="right"
                    >
                        <Button type="default" block onClick={() => handleROIClick()}>
                            <Icon icon="material-symbols:anchor" height={25} color="#05538f"/>
                        </Button>
                    </Popover>
                </Space>
            </div>

            <FleetModal
                visible={isFleetModalVisible}
                onClose={handleCloseFleetModal}
                fleets={fleets}
                setFleets={setFleets}
                fleetUsage={fleetUsage}
                handleFleetUsage={() => setFleetUsage(fleetUsage + 1)}
                handleAddFleet={handleAddFleet}
                setFleetUsage={setFleetUsage}
                setIsFleetMode={setIsFleetMode}
                setAllVesselMMSIs={setAllVesselMMSIs}
            />

            <ROIDrawer
                visible={isRoiDrawerVisible}
                onClose={() => { dispatch(setRoiDrawerVisible(false))}}

                currentZoomLevel={zoomLevel}
            />
        </div>


    );
};

export default MainMapPopover;
